export const routes = {
    HOME: '/',
    ARTICLES: '/',
    LOGIN: ({ lang }: { lang: string }) => `/xyz-network/login/?next=${window.location.pathname}&lang=${lang}`,
    SIGNUP: ({ lang }: { lang: string }) => `/xyz-network/signup/?next=${window.location.pathname}&lang=${lang}`,
    PROFILE_EDIT: ({ lang }: { lang: string }) => `/xyz-network/profile?lang=${lang}`,
    EDITOR: (id?: string | number) => (id ? `/articles/editor/${id}` : '/articles/editor'),
    SEARCH: '/articles/search',
    ARTICLE: (id: string | number) => `/articles/${id}`,
    TOPIC: (slug: string) => `/topic/${slug}`,
    PROFILE: (id: string | number) => `/profile/${id}`,
    DRAFTS: (id: string | number) => `/profile/${id}#drafts`,
    BOOKMARKS: '/bookmarks',
    SUBSCRIPTIONS: '/subscriptions',
    INFO: (slug: string) => `/info/${slug}`,
};
