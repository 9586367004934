import React, { useState, MouseEvent } from 'react';

// @ts-ignore
// eslint-disable-next-line import/named
import { useCurrentUserSettings } from '@/model/User/CurrentUserProvider';
import LiveStreamWidget from '@/ui/LiveStreamWidget';

// @ts-ignore
// eslint-disable-next-line import/named
import { useMedia } from '@/core/media';
import Grid from '../Grid';

import styles from './SchoolStream.scss';

const SchoolStream = () => {
    const { adversity } = useCurrentUserSettings();
    const { xs, sm } = useMedia();

    const [isShown, setIsShown] = useState<boolean>(adversity.liveStream?.isEnabled);

    const handleClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsShown(false);
    };

    if (!isShown) {
        return null;
    }

    return !xs && !sm ? (
        <Grid containerClassName={styles.container}>
            <Grid.Item className={styles.colContainer} xl={3} lg={3} md={3}>
                <LiveStreamWidget
                    url={adversity.liveStream?.url}
                    text={adversity.liveStream?.title}
                    onClose={handleClose}
                />
            </Grid.Item>
        </Grid>
    ) : (
        <LiveStreamWidget url={adversity.liveStream?.url} text={adversity.liveStream?.title} onClose={handleClose} />
    );
};

export default SchoolStream;
