// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import styles from './GridRow.scss';

type Props = {|
    children: Node,
    className?: string,
|};

const GridRow = ({ children, className }: Props): Node => {
    return <div className={classnames(styles.row, className)}>{children}</div>;
};

export default GridRow;
