// @flow strict
import React from 'react';
import { type HeaderNotificationUiType, Icon, Text } from '@xyz-school/xyz-react-frontend';
import { Trans, type TFunction } from 'react-i18next';

import imageKit from '@/core/imageKit';
import { getCommentUrl } from '@/model/Comment';

import { type NotificationType, type CommentNotificationType, type SystemNotificationType } from '../NotificationType';
import { NOTIFICATION_NAME } from '../NotificationName';

import logo from './logo.svg';
import type { ArticleNotificationType } from '../NotificationType';
import { routes } from '../../../journal/constants/urls';

export const getImg = (src?: string): string => imageKit(src, imageKit.SIZE.avatar_xs) || '';

const getComment = (originalComment: string): string =>
    originalComment.length > 30 ? `${originalComment.slice(0, 30)}...` : originalComment;

type CommentNotificationArgType = {|
    ...CommentNotificationType,
    descriptionKey: string,
    t: TFunction,
|};

type ArticleNotificationArgType = {|
    ...ArticleNotificationType,
    descriptionKey: string,
    t: TFunction,
|};

export const getCommentNotification = ({
    payload,
    descriptionKey,
    id,
    readAt,
    createdAt = '',
    t,
}: CommentNotificationArgType): HeaderNotificationUiType => {
    const { article, actor, comment } = payload;
    return {
        id,
        isRead: !!readAt,
        icon: <Icon name="comment" />,
        imageUrl: getImg(actor.avatar),
        date: createdAt,
        description: (
            <Text size="small" color="label">
                <Trans
                    i18nKey={descriptionKey}
                    values={{
                        articleName: article.title,
                        userName: actor.username,
                        comment: comment.text && getComment(comment.text),
                    }}
                    components={{ nameTag: <Text size="small" inline color="text" /> }}
                    t={t}
                />
            </Text>
        ),
        url: getCommentUrl(article.slug, comment.rootReplyId, comment.id) || '',
    };
};

export const getArticleNotification = ({
    payload,
    descriptionKey,
    id,
    readAt,
    createdAt = '',
    t,
}: ArticleNotificationArgType): HeaderNotificationUiType => {
    const { slug, title, cover } = payload;
    return {
        id,
        isRead: !!readAt,
        icon: <Icon name="article" />,
        imageUrl: getImg(cover),
        date: createdAt,
        description: (
            <Text size="small" color="label">
                <Trans
                    i18nKey={descriptionKey}
                    values={{
                        articleName: title,
                    }}
                    components={{ nameTag: <Text size="small" inline color="text" /> }}
                    t={t}
                />
            </Text>
        ),
        url: routes.ARTICLE(slug),
    };
};

const getSystemNotification = ({
    id,
    readAt,
    createdAt = '',
    payload,
}: SystemNotificationType): HeaderNotificationUiType => ({
    id,
    isRead: !!readAt,
    icon: <Icon name="speaker" />,
    date: createdAt,
    imageUrl: logo,
    description: (
        <Text size="small" color="text">
            {payload.text}
        </Text>
    ),
    url: payload.url,
});

type GetNotificationUiDataReturnType = (NotificationType) => ?HeaderNotificationUiType;

export const getNotificationUiData = (t: TFunction): GetNotificationUiDataReturnType => (notification) => {
    switch (notification.type) {
        case NOTIFICATION_NAME.article_comment: {
            return getCommentNotification({ ...notification, descriptionKey: 'DESCRIPTION_COMMENT', t });
        }
        case NOTIFICATION_NAME.comment_reply: {
            return getCommentNotification({ ...notification, descriptionKey: 'DESCRIPTION_REPLAY', t });
        }
        case NOTIFICATION_NAME.delayed_article_published: {
            return getArticleNotification({ ...notification, descriptionKey: 'DELAYED_ARTICLE_PUBLISHED', t });
        }
        case NOTIFICATION_NAME.delayed_article_error: {
            return getArticleNotification({ ...notification, descriptionKey: 'DELAYED_ARTICLE_ERROR', t });
        }
        case NOTIFICATION_NAME.system: {
            return getSystemNotification(notification);
        }
        default:
            return null;
    }
};

export default getNotificationUiData;
