// @flow strict
import { useCallback, useState } from 'react';
import { queryStringify } from '@xyz-school/xyz-react-frontend';

import useAxios from '@/core/useAxios';
import { type NotificationType } from '@/model/Notification';

import { api } from '../../../journal/constants/api';
import usePullingNotification, { type CountType } from '../usePullingNotification';

type FetchApiType = (limit: number) => Promise<Array<NotificationType>>;

type ReturnType = {|
    fetchApi: FetchApiType,
    resetCursor: () => void,
    isNext: boolean,
    unreadCount: CountType,
|};

type GetUrlType = ({| limit: number, cursor?: string |}) => string;

export const getUrl: GetUrlType = ({ limit, cursor }) => `${api.NOTIFICATIONS}?${queryStringify({ limit, cursor })}`;

type MakeFetchWrapperType = ({|
    cursor: ?string,
    setCursor: (?string) => void,
    axiosFetch: (config: { url: string }) => Promise<{|
        data: {| results: Array<NotificationType>, next: ?{| cursor: string |}, unreadCount: number |},
    |}>,
    setUnreadCount: (CountType) => void,
|}) => FetchApiType;

export const makeFetchWrapper: MakeFetchWrapperType = ({ cursor, setCursor, axiosFetch, setUnreadCount }) => (
    limit,
) => {
    if (limit && cursor !== null) {
        return axiosFetch({ url: getUrl({ cursor, limit }) }).then(({ data }) => {
            if (data) {
                if (data.next) setCursor(data.next.cursor);
                else setCursor(null);
                setUnreadCount(data.unreadCount);
                if (data.results) return data.results;
            }
            return [];
        });
    }
    return Promise.resolve([]);
};

const useNotificationsApi = (): ReturnType => {
    const { unreadCount, setUnreadCount } = usePullingNotification();
    const [, axiosFetch] = useAxios(getUrl({ limit: 0 }), { useCache: false, manual: true });
    const [cursor, setCursor] = useState<?string>();

    const resetCursor = useCallback(() => setCursor(), []);

    const fetchWrapper = useCallback(makeFetchWrapper({ cursor, setCursor, axiosFetch, setUnreadCount }), [cursor]);

    return { fetchApi: fetchWrapper, resetCursor, isNext: !!cursor, unreadCount };
};

export default useNotificationsApi;
