// @flow strict
import React, { type Node } from 'react';

import styles from './SidebarItem.scss';

type Props = {|
    children: Node,
|};

const SidebarItem = ({ children }: Props): Node => {
    if (!children) {
        return null;
    }

    return <div className={styles.container}>{children}</div>;
};

export default SidebarItem;
