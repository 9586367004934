// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import Text from '@/typography/Text';

import styles from './NotificationCounterCircle.scss';

type Props = {
    children: string | number,
    className?: string,
};

const NotificationCounterCircle = ({ children, className }: Props): Node => {
    return (
        <div className={classnames(styles.container, className)}>
            <Text color="text-inverted" size="counter">
                {parseInt(children, 10) < 1000 ? children : '999+'}
            </Text>
        </div>
    );
};

export default NotificationCounterCircle;
