import baseAxios, { AxiosInstance, AxiosPromise, Method } from 'axios';

import { getCookie } from '@xyz-school/xyz-react-frontend/lib/utils/cookie';

export const axios: AxiosInstance = baseAxios.create({
    headers: {
        // @todo Переписать после доработки авторизации и перехода на SPA
        'X-CSRFToken': getCookie({ name: 'csrftoken' }),
    },
});

export const setAxiosHeader = (name: string, value: string): void => {
    axios.defaults.headers.common = { ...axios.defaults.headers.common, [name]: value };
};

export const setApiToken = (value: string): void => {
    setAxiosHeader('X-CSRFToken', value);
};

export const setApiLang = (value: string): void => {
    setAxiosHeader('X-Language', value);
};

// TODO change any to unknown and fix prepareToFormData
type RequestType = { [key: string]: any };
type ResponseType = { [key: string]: unknown };

const prepareToFormData = <T extends RequestType>(data?: T): FormData | undefined => {
    if (!data) {
        return undefined;
    }

    const isNeedFormData = !!Object.values(data).find((item) => item instanceof Blob);

    if (isNeedFormData) {
        const formData = new FormData();
        Object.keys(data).forEach((name) => {
            const value = data[name];
            if (value instanceof Blob) {
                formData.append(name, value, `${name}.jpg`);
            } else if (typeof value !== 'undefined') {
                formData.append(name, typeof value === 'object' ? JSON.stringify(value) : value);
            }
        });

        return formData;
    }

    return undefined;
};

const api = <T extends RequestType | undefined = undefined, R extends ResponseType | undefined = undefined>(
    method: Method,
    url: string,
    data?: T,
): AxiosPromise<R> => {
    let request: T | FormData | undefined = data;
    const headers: { [key: string]: string } = {};

    if (request) {
        const formData = prepareToFormData(request);
        if (formData) {
            headers['Content-Type'] = 'multipart/form-data';
            request = formData;
        }
    }

    return axios({
        headers,
        method,
        url,
        data: request,
    });
};

const get = <R extends ResponseType | undefined = undefined>(url: string): AxiosPromise<R> => {
    return api<undefined, R>('GET', url);
};

const post = <T extends RequestType, R extends ResponseType | undefined = undefined>(
    url: string,
    data?: T,
): AxiosPromise<R> => {
    return api<T, R>('POST', url, data);
};

const patch = <T extends RequestType, R extends ResponseType | undefined = undefined>(
    url: string,
    data: T,
): AxiosPromise<R> => {
    return api<T, R>('PATCH', url, data);
};

const remove = <R extends ResponseType | undefined = undefined>(url: string): AxiosPromise<R> => {
    return api<undefined, R>('DELETE', url);
};

export default {
    get,
    patch,
    post,
    delete: remove,
};
