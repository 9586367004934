import React, { MouseEvent } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Container, Link } from '@xyz-school/xyz-react-frontend';

// @ts-ignore
// eslint-disable-next-line import/named
import { useMedia } from '@/core/media';
import Text from '@/typography/Text';
import Icon from '@/ui/Icon';

import styles from './LiveStreamWidget.scss';

type Props = {
    url: string;
    text: string;
    onClose?: (e: MouseEvent<HTMLButtonElement>) => void;
};

const LiveStreamWidget = ({ url, text, onClose }: Props) => {
    const { t } = useTranslation('stream');
    const { xs } = useMedia();

    return (
        <Link to={url} target="_blank" rel="noreferrer">
            <div className={styles.container}>
                <div className={styles.header}>
                    <div className={styles.header_liveContainer}>
                        <div className={styles.redSquareLiveStream} />
                        <Text color="text-inverted" className={styles.highlightedText}>
                            {t('LIVE')}
                        </Text>
                    </div>
                    {!!onClose && (
                        <button type="button" onClick={onClose} className={styles.closeButton}>
                            <Icon name="close" color="text-inverted" />
                        </button>
                    )}
                </div>
                {!xs && (
                    <Container paddingRight={1} paddingLeft={1} paddingTop={1} paddingBottom={1}>
                        <Text size="counter" className={styles.name} color="text-inverted">
                            {text}{' '}
                            <span className={classnames(styles.highlightedText, styles.name)}>{t('WATCH_NOW')}</span>
                        </Text>
                    </Container>
                )}
            </div>
        </Link>
    );
};

export default LiveStreamWidget;
