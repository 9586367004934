// @flow strict

import type { UserSettingsType } from '@/model/User';

export const STORAGE_USER_KEY = 'user';
export const STORAGE_IS_LOGIN_KEY = 'isLogin';
export const STORAGE_SETTINGS_KEY = 'settings';

export const getStorageKeyByLang = (key: string, lang: string): string => `${key}_${lang}`;

export const EMPTY_USER_SETTINGS: UserSettingsType = {
    dictionaries: {
        journalAccess: [],
        articleTypes: [],
        articleStatuses: [],
        topics: [],
        connections: [],
    },
    features: [],
    adversity: {
        liveStream: {
            isEnabled: false,
            title: '',
            url: '',
        },
    },
};
