// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import styles from './ToggleSwitch.scss';

type Props = {|
    isOn?: boolean,
    onToggle: () => void,
    children?: Node,
    className?: string,
|};

const ToggleSwitch = ({ isOn, onToggle, children, className }: Props): Node => {
    return (
        <button type="button" onClick={onToggle} className={classnames(styles.ToggleSwitch, className)}>
            {children}
            <div className={classnames(styles.switch, { [styles.isOn]: isOn })} />
        </button>
    );
};

export default ToggleSwitch;
