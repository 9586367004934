// @flow strict
import React, { type Node } from 'react';

import { Container, Link, Separator } from '@xyz-school/xyz-react-frontend';

import Heading from '@/typography/Heading';
import NotificationItem, { type HeaderNotificationUiType } from './NotificationItem';

export type ListType = Array<HeaderNotificationUiType>;

type Props = {
    list: ListType,
    listTitle: string,
    closeNotifications: () => void,
};

const List = ({ list, listTitle, closeNotifications }: Props): Node => {
    return (
        <Container paddingBottom={0.5}>
            <Heading level={3}>{listTitle}</Heading>
            <Separator marginTop={2} marginBottom={0.5} />
            {list.map(({ url, isRead, ...itemData }) => {
                const { id } = itemData;
                const item = <NotificationItem {...itemData} />;
                return url ? (
                    <div onClick={closeNotifications} key={id}>
                        <Link to={url}>{item}</Link>
                    </div>
                ) : (
                    <div key={id}>{item}</div>
                );
            })}
        </Container>
    );
};

export default List;
