// @flow strict
import { queryStringify } from '@xyz-school/xyz-react-frontend';

import { routes } from '../../../journal/constants/urls';

const getCommentUrl = (articleSlug?: string, treadId?: number, commentId?: number): ?string => {
    if (!articleSlug) return null;
    const treadIdQuery: string = typeof treadId === 'number' ? `?${queryStringify({ treadId })}` : '';
    return `${routes.ARTICLE(articleSlug)}${treadIdQuery}#commentId-${commentId || 'null'}`;
};

export default getCommentUrl;
