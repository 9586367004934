// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@xyz-school/xyz-react-frontend/lib/theme/index';

import ToggleSwitch from '@/ui/ToggleSwitch';
import Text from '@/typography/Text';

type Props = {|
    className?: string,
    textClassName?: string,
|};

const GlobalThemeToggleSwitch = ({ className, textClassName }: Props): Node => {
    const { t } = useTranslation(['header']);
    const { globalTheme, setGlobalTheme, isChangeDisabled } = useTheme();

    if (!globalTheme || isChangeDisabled) {
        return null;
    }

    return (
        <ToggleSwitch
            isOn={globalTheme === 'dark'}
            onToggle={() => setGlobalTheme(globalTheme === 'dark' ? 'light' : 'dark')}
            className={className}
        >
            <Text className={textClassName}>{t('DARK_THEME')}</Text>
        </ToggleSwitch>
    );
};

export default GlobalThemeToggleSwitch;
