export const hideGlobalScrollbar = () => {
    const { body } = document;
    const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
    body.style.overflowY = 'hidden';
    body.style.marginRight = `${scrollBarWidth}px`;
};

export const showGlobalScrollbar = () => {
    const { body } = document;
    body.style.overflowY = 'scroll';
    body.style.marginRight = '0';
};
