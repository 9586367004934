// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@xyz-school/xyz-react-frontend';

import { useMedia } from '@/core/media/useMedia';
import Heading from '@/typography/Heading';
import ArticleTopicsItem from '@/features/ArticleTopics/ArticleTopicsItem';
import type { TopicType } from '@/model/Topic';

import allTopicsIcon from './images/allTopics.png';

import styles from './ArticleTopics.scss';

type Props = {
    currentTopic?: ?$PropertyType<TopicType, 'slug'>,
    topics: TopicType,
    getTopicUrl: (string) => void,
};

const getIsBorderBottomNeeded = (topics, topicToRenderIndex, currentTopic): boolean => {
    if (!topics) {
        return false;
    }

    const topicToRenderSlug = topics[topicToRenderIndex].slug;

    if (topicToRenderIndex < topics.length - 1) {
        return !(currentTopic === topicToRenderSlug || currentTopic === topics[topicToRenderIndex + 1].slug);
    }

    return currentTopic !== topicToRenderSlug;
};

const ArticleTopics = ({ currentTopic, topics, getTopicUrl }: Props): Node => {
    const { sm, xs } = useMedia();
    const isMobileOrTablet = sm || xs;

    const { t } = useTranslation('topicFilter');

    return (
        <div className={styles.container}>
            {!isMobileOrTablet && (
                <Container className={styles.titleContainer}>
                    <Heading level={3}>{t('TOPIC_TITLE')}</Heading>
                </Container>
            )}
            <ArticleTopicsItem
                key="all"
                label={t('ALL_TOPICS_LABEL')}
                icon={allTopicsIcon}
                value=""
                to={getTopicUrl('')}
                isChecked={currentTopic === ''}
                isBorderBottom={currentTopic !== '' && currentTopic !== topics[0].slug}
                isBorderTop={currentTopic !== ''}
            />
            {topics.map((topic, topicIndex) => (
                <ArticleTopicsItem
                    key={topic.slug}
                    label={topic.title}
                    icon={topic.icon}
                    value={topic.slug}
                    to={getTopicUrl(topic.slug)}
                    isChecked={currentTopic === topic.slug}
                    isBorderBottom={getIsBorderBottomNeeded(topics, topicIndex, currentTopic)}
                />
            ))}
        </div>
    );
};

export default ArticleTopics;
