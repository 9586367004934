// @flow strict

import { useCallback, useState } from 'react';
import { useLanguage } from '@xyz-school/xyz-react-frontend';

import useLocalStorage from '@/core/useLocalStorage';
import { userApi, type CurrentUserType } from '@/model/User';

import { getStorageKeyByLang, STORAGE_USER_KEY, STORAGE_IS_LOGIN_KEY } from './settings';
import { type CurrentUserContextType } from './CurrentUserContext';

type UserDataType = {|
    user?: ?CurrentUserType,
    /**
     * Загружен ли пользователь с сервера
     */
    isLoading: boolean,
    isError: boolean,
|};

type UseLoadUserReturnType = [
    UserDataType,
    { loadUser: () => void, updateUser: $PropertyType<CurrentUserContextType, 'updateUser'> },
];

const useLoadUser = (): UseLoadUserReturnType => {
    const { currentLang } = useLanguage();

    const [userInStorage, updateUserInStorage] = useLocalStorage<CurrentUserType>(
        getStorageKeyByLang(STORAGE_USER_KEY, currentLang),
    );
    const [, updateIsLoginInStorage] = useLocalStorage<boolean>(STORAGE_IS_LOGIN_KEY);

    const [localUserData, updateLocalUserData] = useState<{|
        user?: ?CurrentUserType,
        isLoading: boolean,
        isError: boolean,
    |}>({ isLoading: true, isError: false });

    const updateUser = useCallback(
        (cb) => {
            if (typeof cb === 'function') {
                updateLocalUserData((oldData) => {
                    const newUser = cb(oldData.user);
                    updateUserInStorage(newUser);
                    return { ...oldData, user: newUser };
                });
            }
            if (typeof cb === 'object' || typeof cb === 'undefined') {
                updateLocalUserData((oldData) => ({ ...oldData, user: cb }));
                updateUserInStorage(cb);
            }
        },
        [updateLocalUserData, updateUserInStorage],
    );

    const loadUser = useCallback(() => {
        userApi
            .getCurrentUser()
            .then(({ data }) => {
                updateLocalUserData({
                    user: data,
                    isLoading: false,
                    isError: false,
                });
                updateUserInStorage(data);
                updateIsLoginInStorage(true);
            })
            .catch(() => {
                updateUserInStorage();
                updateLocalUserData({ isLoading: false, isError: true });
                updateIsLoginInStorage(false);
            });
    }, [updateLocalUserData, updateUserInStorage]);

    return [
        {
            user: localUserData.user || userInStorage,
            isError: localUserData.isError,
            // return loading only on first request, other always loaded,
            isLoading: localUserData.user ? false : localUserData.isLoading,
        },
        { loadUser, updateUser },
    ];
};

export default useLoadUser;
