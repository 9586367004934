// @flow strict
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotificationActions, useLanguage } from '@xyz-school/xyz-react-frontend';

import { axios } from '@/core/api';
import { type SimpleUserType } from '@/model/User';
import { useCurrentUser } from '@/model/User/CurrentUserProvider';

import { routes } from '../constants/urls';

import useUpdateSubscribers from './useUpdateSubscribers';

type Props = {|
    isCurrentUserSubscribed: $PropertyType<SimpleUserType, 'isCurrentUserSubscribed'>,
    id: $PropertyType<SimpleUserType, 'id'>,
|};

export default ({ isCurrentUserSubscribed, id }: Props) => {
    const { t } = useTranslation(['actions', 'notAuthorized']);
    const { currentLang } = useLanguage();
    const currentUser = useCurrentUser();
    const [isSubscribed, setIsSubscribed] = useState<?boolean>(isCurrentUserSubscribed);
    const { pushNotification } = useNotificationActions();
    const { createSubscriptionEvent } = useUpdateSubscribers();

    useEffect(() => {
        setIsSubscribed(isCurrentUserSubscribed);
    }, [isCurrentUserSubscribed]);

    const onClickSubscribe = () => {
        if (currentUser) {
            axios({
                url: `/api/users/${id}/subscription/`,
                method: isSubscribed ? 'delete' : 'post',
            })
                .then(() => {
                    pushNotification({
                        type: 'success',
                        message: isSubscribed ? t('UNSUBSCRIBE_SUCCESS_MESSAGE') : t('SUBSCRIBE_SUCCESS_MESSAGE'),
                        name: 'successSubscribeToUser',
                    });
                    createSubscriptionEvent(!isSubscribed);
                    setIsSubscribed((prevState) => !prevState);
                })
                .catch(() =>
                    pushNotification({
                        type: 'error',
                        message: t('ERROR_MESSAGE'),
                        name: 'errorSubscribeToUser',
                    }),
                );
        } else {
            pushNotification({
                type: 'warning',
                message: {
                    text: t('NOTIFICATION_AUTH_TEXT'),
                    link: `${window.location.origin}${routes.LOGIN({ lang: currentLang })}`,
                    linkText: t('notAuthorized:signIn'),
                    isLinkExternal: true,
                },
                name: 'SubscribeAuthWarning',
            });
        }
    };

    return { isSubscribed, onClickSubscribe };
};
