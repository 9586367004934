// @flow strict
import React, { type Node, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useMedia } from '@/core/media';
import { hideGlobalScrollbar, showGlobalScrollbar } from '@/core/scrollbarHelper';
import SidebarMenu from '@/features/SidebarMenu';
import Avatar from '@/ui/Avatar';
import Icon from '@/ui/Icon';
import type { UserType } from '@/model/User';

import styles from './HeaderUserMenu.scss';

type Props = {|
    user: UserType,
    isMenuOpened: boolean,
    setIsMenuOpened: (boolean) => void,
|};

const HeaderUserMenu = ({ user, isMenuOpened, setIsMenuOpened }: Props): Node => {
    const location = useLocation();
    const isAuth = !!user && !!Object.keys(user).length;

    const { xs } = useMedia();
    const isMobile = xs;

    useEffect(() => {
        setIsMenuOpened(false);
    }, [location.pathname]);

    const handleMenuClose = useCallback(() => {
        setIsMenuOpened(false);
        if (isMobile) {
            showGlobalScrollbar();
        }
    }, [isMobile]);

    const handleMenuOpen = useCallback(() => {
        setIsMenuOpened(true);
        if (isMobile) {
            hideGlobalScrollbar();
        }
    }, [isMobile]);

    return (
        <>
            {isAuth ? (
                <Avatar userName={user.username} src={user.avatar} alt={user.username} onClick={handleMenuOpen} />
            ) : (
                <Icon name="user-media" onClick={handleMenuOpen} className={styles.icon} />
            )}
            {isMenuOpened && <SidebarMenu onSidebarMenuClose={handleMenuClose} />}
        </>
    );
};

export default HeaderUserMenu;
