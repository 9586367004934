// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import Icon from '@/ui/Icon';

import styles from './DefaultImage.scss';

type Props = {
    className?: string,
};

const DefaultImage = ({ className }: Props): Node => {
    return (
        <div className={classnames(styles.wrapper, className)}>
            <Icon name="bell" color="icon" />
        </div>
    );
};

export default DefaultImage;
