// @flow strict
import { createMedia } from '@artsy/fresnel';
import { breakpoints } from './breakpoints';

const AppMedia = createMedia({
    breakpoints: Object.fromEntries(breakpoints),
});

const mediaStyle = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

export { mediaStyle, Media, MediaContextProvider };
