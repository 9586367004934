// @flow strict
import { useCallback } from 'react';
import { useQueryUrl } from '@xyz-school/xyz-react-frontend';

import { SORT_TYPE, type SortTypeType } from './sort';

type SortCallbackType = (?SortTypeType | ?string) => void;

const SORT_QUERY_KEY = 'sort';

/**
 * Convert any value to valid sort string
 */
export const normalizeSort = (value: mixed, defaultValue: SortTypeType): SortTypeType => {
    if (value && typeof value === 'string' && SORT_TYPE[value]) {
        return SORT_TYPE[value];
    }
    return defaultValue;
};

/**
 * get current sort and change it in location search
 */
export const useSort = (defaultSort: SortTypeType): [SortTypeType, SortCallbackType] => {
    const { params, setQuery } = useQueryUrl();
    const currentSort = normalizeSort(params[SORT_QUERY_KEY], defaultSort);

    const setSort = useCallback(
        (value) => {
            const newCurrentSort = normalizeSort(value, defaultSort);
            setQuery({ [SORT_QUERY_KEY]: newCurrentSort === defaultSort ? '' : newCurrentSort });
        },
        [defaultSort, setQuery],
    );

    return [currentSort, setSort];
};
