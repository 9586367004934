// @flow
import * as React from 'react';
import SVG from 'react-inlinesvg';
import IconsSVG from '@/ui/Icon/assets/icons.svg';

function IconLoader() {
    return <SVG src={IconsSVG} style={{ display: 'none' }} />;
}

export default IconLoader;
