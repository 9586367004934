// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import Heading from '@/typography/Heading';
import Text from '@/typography/Text';

import styles from './EmptyState.scss';

const EmptyState = (): Node => {
    const { t } = useTranslation(['header']);
    return (
        <div>
            <Heading level={3} className={styles.heading}>
                {t('EMPTY_NOTIFICATION_HEADING')}
            </Heading>
            <Text size="label" color="label">
                {t('EMPTY_NOTIFICATION_TEXT')}
            </Text>
        </div>
    );
};

export default EmptyState;
