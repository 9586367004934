// @flow strict
import { useState, useEffect, useCallback } from 'react';

import { breakpoints } from '../breakpoints';

const getSize = (): number => {
    return window.innerWidth;
};

type BreakpointObjectType = {
    name: string,
    value: number,
};

const breakpointsWithObjects: Array<BreakpointObjectType> = breakpoints.map(([name, value]) => ({ name, value }));

export const findBreakpoint = (size: number) => (item: BreakpointObjectType, index: number): boolean =>
    Number(item.value) <= size &&
    (index === breakpointsWithObjects.length - 1 || Number(breakpointsWithObjects[index + 1].value) > size);

type CurrentBreakpointsType = {|
    xs: boolean,
    sm: boolean,
    md: boolean,
    lg: boolean,
    xl: boolean,
|};

export const getBreakpoint = (): $Shape<CurrentBreakpointsType> => {
    const size = getSize();
    const newBreakpoint = breakpointsWithObjects.find(findBreakpoint(size));
    if (newBreakpoint) {
        return { [newBreakpoint.name]: true };
    }
    return {};
};

const initialBreakpoints: CurrentBreakpointsType = {
    xs: false,
    sm: false,
    md: false,
    lg: false,
    xl: false,
};

const setBreakpoints = () => ({ ...initialBreakpoints, ...getBreakpoint() });

export const useMedia = (): CurrentBreakpointsType => {
    const [currentBreakpoints, setCurrentBreakpoints] = useState<CurrentBreakpointsType>(setBreakpoints());

    const onResize = useCallback(() => setCurrentBreakpoints(setBreakpoints()), []);

    useEffect(() => {
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [onResize]);

    return currentBreakpoints;
};
