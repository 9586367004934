// @flow
import { type AxiosPromise } from 'axios';
import api from '@/core/api';

import type { CurrentUserType, UserSettingsType, LoginDTO, ChangeInfoDTO, ChangePasswordDTO } from '@/model/User';

export const getCurrentUser = (): AxiosPromise<CurrentUserType> => {
    return api.get<CurrentUserType>('/api/users/me/');
};

export const getCurrentUserSettings = (): AxiosPromise<UserSettingsType> => {
    return api.get<UserSettingsType>('/api/settings/');
};

export const login = (data: LoginDTO): AxiosPromise<CurrentUserType> => {
    return api.post<LoginDTO, CurrentUserType>('/api/users/login/', {
        ...data,
        remember_me: data.rememberMe,
    });
};

export const logout = (): AxiosPromise<void> => {
    return api.delete('/api/users/login/');
};

export const changeAvatar = (id: string | number, { photo }: { photo: File | Blob }): AxiosPromise<CurrentUserType> => {
    const formData = new FormData();
    formData.append('avatar', photo, 'crop.jpg');

    return api.patch<FormData, CurrentUserType>(`/api/users/${id}/`, formData);
};

export const changeInfo = (id: string | number, data: ChangeInfoDTO): AxiosPromise<CurrentUserType> => {
    return api.patch<ChangeInfoDTO, CurrentUserType>(`/api/users/${id}/`, data);
};

export const changePassword = (data: ChangePasswordDTO): AxiosPromise<CurrentUserType> => {
    return api.post<ChangePasswordDTO, CurrentUserType>('/api/users/change_password/', data);
};
