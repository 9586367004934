// @flow strict
import React, { useCallback, useContext, useEffect, useState, createContext, type Node } from 'react';

import { Container, NotificationList } from '@xyz-school/xyz-react-frontend';

import PageChangeScrollToTop from '@/core/PageChangeScrollToTop';
import PageLayoutUi from '@/ui/PageLayoutUI';
import PageBottomContainer from '@/ui/PageBottomContainer/PageBottomContainer';
import AuthModal from '@/features/AuthModal/AuthModal';
import SchoolStream from '@/features/SchoolStream';
import ScrollToTopButton from '@/features/ScrollToTopButton';
import Header from '@/features/Header/Header';

import styles from './PageLayout.scss';

type Props = {|
    children: Node,
|};

type SlotType = 'header' | 'breadcrumbs';

type PageLayoutContextType = { setBlock: (string, Node) => void, removeBlock: (string) => void };
const PageLayoutContext = createContext<PageLayoutContextType>({});

const PageLayout = ({ children }: Props) => {
    const [blocks, setBlocks] = useState<{ [SlotType]: Node }>({});

    const setBlock = useCallback(
        (name, block) => {
            setBlocks((oldState) => ({ ...oldState, [name]: block }));
        },
        [setBlocks],
    );
    const removeBlock = useCallback(
        (name) => {
            setBlocks((oldState) => ({ ...oldState, [name]: null }));
        },
        [setBlocks],
    );

    return (
        <PageLayoutContext.Provider value={{ setBlock, removeBlock }}>
            <PageLayoutUi
                header={
                    <>
                        <Header />
                        {blocks.header}
                        <div className={styles.notificationWrapper}>
                            <NotificationList />
                        </div>
                        <AuthModal />
                    </>
                }
            >
                <PageChangeScrollToTop />

                <Container marginTop={4} marginTopSm={4} className={styles.content}>
                    {children}
                </Container>

                <PageBottomContainer>
                    <ScrollToTopButton />
                    <SchoolStream />
                </PageBottomContainer>
            </PageLayoutUi>
        </PageLayoutContext.Provider>
    );
};

const PageLayoutSlot = ({ name, children }: { name: SlotType, children: Node }) => {
    const { setBlock, removeBlock } = useContext(PageLayoutContext);

    useEffect(() => {
        setBlock(name, children);
        return () => {
            return removeBlock(name);
        };
    }, [name, children]);

    return null;
};

PageLayout.Slot = PageLayoutSlot;

export default PageLayout;
