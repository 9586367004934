// @flow strict
import React, { type Node, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { Container, NavLink, useLanguage, useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import { scrollToTop } from '@/core/scrollToTop';
import { useMedia } from '@/core/media';
import Text from '@/typography/Text';
import Button from '@/ui/Button';
import Icon from '@/ui/Icon';
import GlobalThemeToggleSwitch from '@/features/GlobalThemeToggleSwitch';
import { commonNavigation, profileNavigation } from '@/features/PageLayout/constants';
import type { CurrentUserType } from '@/model/User';
import { userApi } from '@/model/User';

import { routes } from '../../../journal/constants/urls';

import styles from './SidebarMenuNavigation.scss';

type Props = {|
    isAuth: boolean,
    currentUserId: $PropertyType<CurrentUserType, 'id'>,
|};

const SidebarMenuNavigation = ({ isAuth, currentUserId }: Props): Node => {
    const { xs } = useMedia();
    const isMobile = xs;

    const { t } = useTranslation(['actions', 'navTitles', 'header']);
    const { toLocalizedRoute } = useLanguage();

    const history = useLocalizedHistory();
    const location = useLocation();

    const handleLogout = useCallback(() => {
        userApi.logout().then(() => {
            window.location.reload();
        });
    }, [userApi, window.location]);

    const handleLogin = useCallback(() => {
        history.replace({
            ...location,
            state: {
                ...(location.state || {}),
                authModal: true,
            },
        });
    }, [history, location]);

    return isAuth ? (
        <div className={styles.container}>
            {profileNavigation(currentUserId).map((item) => (
                <NavLink
                    key={item.title}
                    to={item.href}
                    className={styles.sidebarNavigationLink}
                    activeClassName={styles.sidebarNavigationLinkActive}
                    onActiveNavLinkClick={scrollToTop}
                >
                    <Container marginRight={1}>
                        <Icon className={styles.icon} name={item.icon} />
                    </Container>
                    <Text color="inherit">{t(`navTitles:${item.title}`)}</Text>
                </NavLink>
            ))}
            {isMobile &&
                commonNavigation.map((item) => (
                    <NavLink
                        key={item.title}
                        to={item.href}
                        className={styles.sidebarNavigationLink}
                        activeClassName={styles.sidebarNavigationLinkActive}
                        onActiveNavLinkClick={scrollToTop}
                    >
                        <Container marginRight={1}>
                            <Icon className={styles.icon} name={item.icon} />
                        </Container>
                        <Text color="inherit">{t(`navTitles:${item.title}`)}</Text>
                    </NavLink>
                ))}
            <button type="button" className={styles.logoutButton} onClick={handleLogout}>
                <Container marginRight={1}>
                    <Icon className={styles.icon} name="exit-media" />
                </Container>
                <Text color="inherit">{t(`header:LOGOUT_BTN_TEXT`)}</Text>
            </button>
            {isMobile && (
                <>
                    <Button
                        styleType="primary"
                        className={styles.writeArticleButton}
                        linkTo={toLocalizedRoute(routes.EDITOR())}
                    >
                        {t('actions:WRITE_AN_ARTICLE')}
                    </Button>
                    <GlobalThemeToggleSwitch className={styles.themeToggle} />
                </>
            )}
        </div>
    ) : (
        <div className={styles.mobileContainer}>
            <Button styleType="primary" onClick={handleLogin} className={styles.loginButton}>
                {t('header:LOGIN_BTN_TEXT')}
            </Button>
            <GlobalThemeToggleSwitch className={styles.themeToggle} />
        </div>
    );
};

export default SidebarMenuNavigation;
