// @flow strict

import { type AxiosPromise } from 'axios';
import api from '@/core/api';

import type { CommentAttachmentsType } from '@/model/Comment/commentDTO';
import { buildUrl } from '../../journal/utils/url';
import type { CommentsFilterType } from './commentsFilterType';
import type { CommentDTO, CommentType, CommentsListType } from './index';

export const addComment = (data: CommentDTO): AxiosPromise<CommentType> => {
    return api.post<CommentDTO, CommentType>('/api/comments/', data);
};

export const editComment = (
    id: number,
    text: string,
    attachments: CommentAttachmentsType,
): AxiosPromise<CommentType> => {
    return api.patch<{ text: string }, CommentType>(`/api/comments/${id}/`, { text, attachments });
};

export const removeComment = (id: number): AxiosPromise<void> => {
    return api.delete(`/api/comments/${id}`);
};

export const restoreComment = (id: number): AxiosPromise<CommentType> => {
    return api.post<void, CommentType>(`/api/comments/${id}/restore/`);
};

export const getParentCommentsWithReplies = (
    articleId: number,
    params: CommentsFilterType,
): AxiosPromise<CommentsListType> => {
    return api.get<CommentsListType>(buildUrl(`/api/articles/${articleId}/comments`, params));
};

export const getCommentsByParams = (params: CommentsFilterType): AxiosPromise<CommentsListType> => {
    return api.get<CommentsListType>(buildUrl('/api/comments/', params));
};
