// @flow strict
import React, { type Node } from 'react';

import { Container, Link } from '@xyz-school/xyz-react-frontend';

import { useMedia } from '@/core/media';
import Avatar from '@/ui/Avatar';
import Heading from '@/typography/Heading';
import type { CurrentUserType } from '@/model/User';
import { routes } from '../../../journal/constants/urls';

import styles from './SidebarMenuUser.scss';

type Props = {|
    id: $PropertyType<CurrentUserType, 'id'>,
    username: $PropertyType<CurrentUserType, 'username'>,
    avatar: $PropertyType<CurrentUserType, 'avatar'>,
|};

const SidebarMenuUser = ({ id, username, avatar }: Props): Node => {
    const { xs } = useMedia();
    const isMobile = xs;

    return (
        <Link to={routes.PROFILE(id)} className={styles.container}>
            <Avatar size={!isMobile ? 'extra-large' : 'large'} src={avatar} userName={username} />
            <Container marginTop={2}>
                <Heading level={2}>{username}</Heading>
            </Container>
        </Link>
    );
};

export default SidebarMenuUser;
