import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getCounter } from './yandexMetrika';

let prev: string;

const hit = ({ pathname, search }: { pathname: string; search: string }) => {
    const url = `${pathname}${search}`;

    const counter = getCounter();

    if (counter) {
        counter.hit(`${pathname}${search}`, {
            referer: prev,
        });
        prev = url;
    }
};

const YandexHitUrl = (): null => {
    const location = useLocation();
    useEffect(() => {
        hit(location);
    }, [location.pathname, location.search]);

    return null;
};

export default YandexHitUrl;
