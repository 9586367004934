// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import imageKit from '@/core/imageKit';

import styles from './Avatar.scss';

export type AvatarSizeType = 'standard' | 'small' | 'large' | 'extra-large';

type Props = {
    onClick?: (SyntheticMouseEvent<HTMLElement>) => void | Promise<mixed>,
    /**
     * Controls the size of the avatar.
     */
    size?: AvatarSizeType,
    /**
     * The address of the image for the avatar.
     */
    src?: ?string,
    /**
     * Defines an alternative text description of the image.
     */
    alt?: ?string,
    /**
     * Displays an identifier indicating that the user is "online".
     */
    online?: ?boolean,
    /**
     * For displaying the first letter of the user name if the avatar image is not loaded.
     */
    userName: string,
    /**
     * Custom class
     */
    className?: string,
    /**
     * Add an opacity style
     */
    isDisabled?: boolean,
};

function Avatar({
    onClick,
    size = 'standard',
    src,
    alt,
    online = false,
    userName,
    className,
    isDisabled = false,
}: Props): Node {
    const nameFirstLetter = userName != null ? userName.charAt(0) : '';
    const isClickable = Boolean(onClick);
    const Tag = isClickable ? 'button' : 'div';
    return (
        <Tag
            type={isClickable ? 'button' : undefined}
            onClick={onClick}
            className={classnames(styles.avatar, className, styles[`size--${size}`], {
                [styles.isClickable]: isClickable,
                [styles.isDisabled]: isDisabled,
            })}
        >
            {src ? (
                <img src={imageKit(src, imageKit.SIZE.avatar_sm)} alt={alt} className={styles.image} />
            ) : (
                <div className={styles.firstLetter}> {nameFirstLetter} </div>
            )}
            {online && <div className={styles.online} />}
        </Tag>
    );
}

export default Avatar;
