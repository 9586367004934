import React from 'react';
import classnames from 'classnames';

import { Icon } from '@xyz-school/xyz-react-frontend';

import styles from './AuthModalSocialButtonsItem.scss';

type Props = {
    provider: string;
    onClick: () => void;
};

const AuthModalSocialButtonsItem = ({ provider, onClick }: Props) => {
    return (
        <div className={classnames(styles.wrapper, styles[provider])}>
            <button className={styles.button} type="button" onClick={onClick}>
                <Icon name={provider} className={styles.icon} size="extra-large" />
            </button>
        </div>
    );
};

export default AuthModalSocialButtonsItem;
