// @flow strict

import { useCallback, useState } from 'react';
import { useLanguage } from '@xyz-school/xyz-react-frontend';

import useLocalStorage from '@/core/useLocalStorage';
import { userApi, type UserSettingsType } from '@/model/User';

import { EMPTY_USER_SETTINGS, getStorageKeyByLang, STORAGE_SETTINGS_KEY } from './settings';

type SettingsDataType = {|
    settings: UserSettingsType,
    isLoading: boolean,
    isError: boolean,
|};

type UserLoadSettingsReturnType = [SettingsDataType, { loadSettings: () => void }];

const useLoadSettings = (): UserLoadSettingsReturnType => {
    const { currentLang } = useLanguage();
    const [settingsInStorage, updateSettingsInStorage] = useLocalStorage<UserSettingsType>(
        getStorageKeyByLang(STORAGE_SETTINGS_KEY, currentLang),
    );
    const [localSettingsData, updateLocalSettingsData] = useState<SettingsDataType>({
        settings: EMPTY_USER_SETTINGS,
        isLoading: true,
        isError: false,
    });

    const loadSettings = useCallback(() => {
        userApi
            .getCurrentUserSettings()
            .then(({ data }) => {
                updateLocalSettingsData({
                    settings: data,
                    isLoading: false,
                    isError: false,
                });
                updateSettingsInStorage(data);
            })
            .catch(() => {
                updateSettingsInStorage();
                updateLocalSettingsData((oldData) => ({ ...oldData, isLoading: false, isError: true }));
            });
    }, [updateSettingsInStorage, updateLocalSettingsData]);

    return [
        {
            settings: { ...localSettingsData.settings, ...settingsInStorage },
            isLoading: localSettingsData.isLoading,
            isError: localSettingsData.isError,
        },
        {
            loadSettings,
        },
    ];
};

export default useLoadSettings;
