// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import { ThemeProvider, LoadingSpinner } from '@xyz-school/xyz-react-frontend';

import Icon from '@/ui/Icon';
import useLoading from './useLoading';

import styles from './Button.scss';

const SIZE = Object.freeze({
    normal: 'normal',
    large: 'large',
});

export type ButtonSizeType = $Values<typeof SIZE>;

const STYLE = Object.freeze({
    default: 'default',
    primary: 'primary',
    secondary: 'secondary',
    /**
     * @deprecated need remove, used only in Media: commentForm,
     */
    text: 'text',
});

export type ButtonStyleType = $Values<typeof STYLE>;

const TYPE = Object.freeze({
    button: 'button',
    submit: 'submit',
    reset: 'reset',
});

export type ButtonTypeType = $Values<typeof TYPE>;

export type Props = {|
    className?: string,
    icon?: string,
    children?: Node,
    styleType?: ButtonStyleType,
    size?: ButtonSizeType,
    isDisabled?: boolean,
    isActive?: boolean,
    isFullWidth?: boolean,
    isLoading?: boolean,
    isHover?: boolean,
    isPressed?: boolean,
    isFocus?: boolean,
    // only for button
    type?: ButtonTypeType,
    onClick?: (SyntheticMouseEvent<HTMLButtonElement | HTMLAnchorElement>) => mixed,
    // only for link
    linkTo?: string,
    linkTarget?: string,
|};

const ButtonIcon = ({ styleType, isLoading, icon }: $Shape<Props>) => {
    if (isLoading) {
        if (styleType === STYLE.secondary) {
            return (
                <ThemeProvider theme="invert" className={styles.icon}>
                    <LoadingSpinner />
                </ThemeProvider>
            );
        }
        return <LoadingSpinner className={styles.icon} />;
    }
    if (icon) {
        return <Icon name={icon} className={styles.icon} color="inherit" />;
    }
    return null;
};

const Button = ({
    className,
    children = null,
    size = SIZE.normal,
    type = TYPE.button,
    styleType = STYLE.secondary,
    isDisabled = false,
    isActive,
    isHover,
    isPressed,
    isFocus,
    isFullWidth = false,
    icon,
    linkTo,
    linkTarget,
    onClick,
    isLoading = false,
}: Props): Node => {
    const isLoadingShow = useLoading(isLoading);

    const handleClick = (e) => {
        if (isLoadingShow || isDisabled || !onClick) {
            return;
        }
        onClick(e);
    };

    const content = (
        <>
            <ButtonIcon styleType={styleType} isLoading={isLoadingShow} icon={icon} />
            {!!children && <span className={styles.content}>{children}</span>}
        </>
    );

    const buttonClassName = classnames(styles.button, className, styles[styleType], [styles[size]], {
        [styles.active]: isActive,
        [styles.pressed]: isPressed,
        [styles.focus]: isFocus,
        [styles.hover]: isHover,
        [styles.full]: isFullWidth,
        [styles.square]: !children,
        [styles.loadingWithText]: !icon && isLoadingShow && !!children,
    });

    if (linkTo && !isDisabled && !isLoadingShow) {
        return (
            <a className={buttonClassName} href={linkTo} target={linkTarget}>
                {content}
            </a>
        );
    }

    return (
        // eslint-disable-next-line react/button-has-type
        <button className={buttonClassName} type={type} disabled={isDisabled} onClick={handleClick}>
            {content}
        </button>
    );
};

Button.SIZE = SIZE;
Button.STYLE = STYLE;

export default Button;
