// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@/ui/Button';
import List, { type ListType } from '../List';
import EmptyState from '../EmptyState';

const getFilteredList = (notifications: ListType, isRead: boolean): ListType =>
    notifications.filter((item) => item.isRead === isRead);

export type NotificationsDataType = {
    notifications: ListType,
    isLoaded: boolean,
};

type Props = {
    ...NotificationsDataType,
    load: () => Promise<void>,
    isNext: boolean,
    closeNotifications: () => void,
};

const NotificationsContent = ({ notifications, isLoaded, load, isNext, closeNotifications }: Props): Node => {
    const { t } = useTranslation(['header']);

    if (!isLoaded) {
        return null;
    }

    const isEmpty = !notifications.length;

    if (isEmpty) {
        return <EmptyState />;
    }

    const newNotifications = getFilteredList(notifications, false);
    const oldNotifications = getFilteredList(notifications, true);

    return (
        <div>
            {newNotifications.length ? (
                <List
                    list={newNotifications}
                    listTitle={t('NEW_NOTIFICATIONS')}
                    closeNotifications={closeNotifications}
                />
            ) : null}
            {oldNotifications.length ? (
                <List
                    list={oldNotifications}
                    listTitle={t('OLD_NOTIFICATIONS')}
                    closeNotifications={closeNotifications}
                />
            ) : null}
            {isNext && (
                <Button styleType="default" onClick={load} isFullWidth>
                    {t('MORE_NOTIFICATIONS')}
                </Button>
            )}
        </div>
    );
};

export default NotificationsContent;
