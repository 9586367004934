import { useEffect } from 'react';

const handler = () => {
    if (document.activeElement && document.activeElement.toString() === '[object HTMLButtonElement]') {
        if (document.activeElement instanceof HTMLButtonElement) {
            document.activeElement.blur();
        }
    }
};

const ButtonClickUnfocusHandler = (): null => {
    useEffect(() => {
        document.addEventListener('click', handler);
        return () => {
            document.removeEventListener('click', handler);
        };
    }, [document]);
    return null;
};

export default ButtonClickUnfocusHandler;
