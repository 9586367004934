// @flow strict
import type { ProfileLinkType } from '@xyz-school/xyz-react-frontend/lib/types';
import { routes } from '../../journal/constants/urls';

export const common = {
    socials: [
        {
            iconName: 'facebook',
            link: 'https://www.facebook.com/gamedev.xyz',
        },
        {
            iconName: 'vk',
            link: 'https://vk.com/xyz_gamedev',
        },
        {
            iconName: 'youtube',
            link: 'https://www.youtube.com/channel/UCtJsE0SDhyS4ib2evb5k3gg/videos',
        },
        {
            iconName: 'instagram',
            link: 'https://www.instagram.com/xyz.school/',
        },
        {
            iconName: 'telegram',
            link: 'https://t.me/xyz_cg',
        },
    ],
    email: 'hello@media-xyz.com',
};

export const commonNavigation = [
    {
        title: 'FAVORITES',
        href: routes.BOOKMARKS,
        icon: 'bookmark-media',
    },
    {
        title: 'SUBSCRIPTIONS',
        href: routes.SUBSCRIPTIONS,
        icon: 'people-media',
    },
];

export const profileNavigation = (userId: number | string): Array<ProfileLinkType> => [
    {
        icon: 'user-media',
        title: 'PROFILE',
        href: routes.PROFILE(userId),
    },
    {
        icon: 'file-media',
        title: 'DRAFTS',
        href: routes.DRAFTS(userId),
    },
];

export const footerNavigation = (currentLang: string) => [
    commonNavigation,
    [
        {
            title: 'PRIVACY_POLICY',
            href: `https://network-xyz.com/${currentLang}/info/privacy-policy`,
            target: '_blank',
        },
        {
            title: 'AGREEMENT',
            href: `https://network-xyz.com/${currentLang}/info/agreement`,
            target: '_blank',
        },
    ],
];

export const rulesNavigation = [
    {
        title: 'WEBSITE_RULES',
        href: routes.INFO('rules'),
    },
    {
        title: 'ABOUT',
        href: routes.INFO('about'),
    },
];
