type CreateEventDTO = {
    name: string;
    detail?: {};
    bubbles?: boolean;
    cancelable?: boolean;
};

const createEvent = ({ name, detail, bubbles = true, cancelable = true }: CreateEventDTO): any =>
    document.dispatchEvent(
        new CustomEvent(name, {
            detail,
            bubbles,
            cancelable,
        }),
    );

export default createEvent;
