// @flow strict
import React, { type Node } from 'react';

import { DateFormatter } from '@xyz-school/xyz-react-frontend';

import Text from '@/typography/Text';
import DefaultImage from './DefaultImage';

import styles from './NotificationItem.scss';

export type HeaderNotificationUiType = {|
    id: string,
    icon?: Node,
    imageUrl?: string,
    description?: Node,
    date?: string,
    url?: string,
    isRead: boolean,
|};

const Description = ({ children }: { children: Node }): Node =>
    typeof children === 'string' ? (
        <Text className={styles.text} size="label" wrap>
            {children}
        </Text>
    ) : (
        <div className={styles.text}>{children}</div>
    );

type Props = $Rest<HeaderNotificationUiType, {| url?: string, isRead: boolean |}>;

const NotificationItem = ({ icon, imageUrl, description, date }: Props): Node => (
    <div className={styles.wrapper}>
        {imageUrl ? (
            <img src={imageUrl} className={styles.img} width={40} height={40} alt="" />
        ) : (
            <DefaultImage className={styles.img} />
        )}
        <div>
            {icon && <div className={styles.iconWrapper}>{icon}</div>}
            {description && <Description>{description}</Description>}
            {date && (
                <Text size="label" color="label">
                    <DateFormatter date={date} />
                </Text>
            )}
        </div>
    </div>
);

export default NotificationItem;
