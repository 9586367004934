// @flow strict
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useLanguage } from '@xyz-school/xyz-react-frontend';

const AppMetaTags = (): Node => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { currentLang } = useLanguage();

    const url = `${window.location.origin}${pathname}`;
    const description = t('META_DESCRIPTION');

    return (
        <Helmet titleTemplate={t('HEAD_TITLE_TEMPLATE')}>
            <html lang={currentLang} />
            <link rel="canonical" href={url} />
            <meta property="og:type" content="website" />
            <meta property="og:url" content={url} />
            <meta property="twitter:url" content={url} />
            <meta property="twitter:card" content="summary_large_image" />
            <meta name="description" content={description} />
            <meta property="og:description" content={description} />
            <meta property="twitter:description" content={description} />
            <meta property="og:image" content={`/images/og-${currentLang}.png`} />
        </Helmet>
    );
};

export default AppMetaTags;
