/**
 * Справочник методов
 * @see https://yandex.ru/support/metrika/objects/method-reference.html
 */

export const YANDEX_METRIKA_ID = typeof __YANDEX_METRIKA_ID__ !== 'undefined' ? __YANDEX_METRIKA_ID__ : '';

// FIXME набо как-то заменить это код, а так же добавить
export const getCounter = (): any => window[`yaCounter${YANDEX_METRIKA_ID}` as any];

export const reachGoal = ({ goal, params = {} }: { goal: string; params: {} }) => {
    const counter = getCounter();

    if (counter) {
        counter.reachGoal(goal, params);
    } else {
        // eslint-disable-next-line
        console.log('Yandex Metrica.  Goal ', goal, ' achieved!', params);
    }
};

/**
 * Передача идентификатора посетителя сайта
 */
export const setUserID = (id: string | number) => {
    const counter = getCounter();
    if (counter) {
        counter.setUserID(id);
    } else {
        // eslint-disable-next-line
        console.log('Yandex Metrica. Set user ID: ', id);
    }
};

/**
 * Передача произвольных параметров посетителей сайта.
 */
export const userParams = (params: { isLogin: boolean; role?: string; theme?: string }) => {
    const counter = getCounter();

    if (counter) {
        counter.userParams(params);
    } else {
        // eslint-disable-next-line
        console.log('Yandex Metrica. Set user params: ', params);
    }
};
