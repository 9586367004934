// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, NavLink, useLanguage } from '@xyz-school/xyz-react-frontend';

import Button from '@/ui/Button';
import Text from '@/typography/Text';
import { scrollToTop } from '@/core/scrollToTop';
import { commonNavigation } from '@/features/PageLayout/constants';
import Icon from '@/ui/Icon';
import { routes } from '../../../journal/constants/urls';

import styles from './HeaderNavigation.scss';

const HeaderNavigation = (): Node => {
    const { t } = useTranslation(['actions', 'navTitles']);
    const { toLocalizedRoute } = useLanguage();

    return (
        <div className={styles.container}>
            <Button styleType="primary" linkTo={toLocalizedRoute(routes.EDITOR())}>
                {t('actions:WRITE_AN_ARTICLE')}
            </Button>
            {commonNavigation.map((item) => (
                <NavLink
                    to={item.href}
                    className={styles.headerLink}
                    activeClassName={styles.headerLinkActive}
                    onActiveNavLinkClick={scrollToTop}
                >
                    <Container marginRight={1}>
                        <Icon className={styles.icon} name={item.icon} />
                    </Container>
                    <Text color="inherit">{t(`navTitles:${item.title}`)}</Text>
                </NavLink>
            ))}
        </div>
    );
};
export default HeaderNavigation;
