// @flow
import React, { Suspense } from 'react';
import { render } from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { PageError500 } from '@xyz-school/xyz-react-frontend';
import smoothscroll from 'smoothscroll-polyfill';

import App from './journal/App';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({
        dsn: 'https://39a2a1fc15404193a286c31aea2c3912@o287829.ingest.sentry.io/5414417',
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.2,
        sampleRate: 0.25,
        environment: window.location.hostname.startsWith('media-xyz') ? 'prod' : 'stage',
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
            // kaspersky-labs.com
            'FD126C42-EBFA-4E12-B309-BB3FDD723AC1',
        ],
        ignoreUrls: ['FD126C42-EBFA-4E12-B309-BB3FDD723AC1'],
    });
}

smoothscroll.polyfill();

const container = document.getElementById('root');
if (container) {
    render(
        <Suspense fallback={<div />}>
            <Sentry.ErrorBoundary fallback={<PageError500 />}>
                <App />
            </Sentry.ErrorBoundary>
        </Suspense>,
        container,
    );
}
