// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from '@xyz-school/xyz-react-frontend';

import Text from '@/typography/Text';
import { rulesNavigation } from '@/features/PageLayout/constants';

import styles from './SidebarMenuExternalLinks.scss';

const SidebarMenuExternalLinks = (): Node => {
    const { t } = useTranslation(['header', 'navTitles']);

    return (
        <div className={styles.container}>
            <Link target="_blank" rel="noreferrer" to="https://bit.ly/3jmlIxG" className={styles.externalLink}>
                <Text tag="span" color="inherit">
                    {t('header:XYZ_PROMO')}
                </Text>
            </Link>
            {rulesNavigation.map((item) => (
                <Link key={item.title} target="_blank" rel="noreferrer" to={item.href} className={styles.externalLink}>
                    <Text tag="span" color="inherit">
                        {t(`navTitles:${item.title}`)}
                    </Text>
                </Link>
            ))}
        </div>
    );
};

export default SidebarMenuExternalLinks;
