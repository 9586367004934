// @flow strict
import React, { type Node } from 'react';

import { Container, Link } from '@xyz-school/xyz-react-frontend';

import Icon from '@/ui/Icon';

import styles from './MediaSocialExternalLinks.scss';

const MediaSocialExternalLinks = (): Node => {
    return (
        <div className={styles.container}>
            <a href="mailto:hello@school-xyz.com" rel="nofollow noindex" className={styles.emailLink}>
                hello@school-xyz.com
            </a>
            <Container marginTop={2} className={styles.socialLinksContainer}>
                <Link
                    to="https://vk.com/xyz_gamedev"
                    rel="nofollow noindex"
                    target="_blank"
                    className={styles.socialLink}
                >
                    <Icon size="large" name="vk-media" />
                </Link>
                <Link
                    to="https://twitter.com/XYZGamedev"
                    rel="nofollow noindex"
                    target="_blank"
                    className={styles.socialLink}
                >
                    <Icon size="large" name="twitter-media" />
                </Link>
                <Link
                    to="https://www.youtube.com/XYZSchool"
                    rel="nofollow noindex"
                    target="_blank"
                    className={styles.socialLink}
                >
                    <Icon size="large" name="youtube-media" />
                </Link>
                <Link
                    to="https://zen.yandex.ru/xyz"
                    rel="nofollow noindex"
                    target="_blank"
                    className={styles.socialLink}
                >
                    <Icon size="large" name="dzen-media" />
                </Link>
            </Container>
        </div>
    );
};

export default MediaSocialExternalLinks;
