// https://github.com/cshum/imagor
const SIZE = {
    avatar_xs: 'avatar_xs', // 40x40
    avatar_sm: 'avatar_sm', // 120x120
    avatar_lg: 'avatar_lg', // 350x350
    article_cover: 'article_cover', // 600x338
    article_image: 'article_image', // 846x700 c-at_max
    article_image_full: 'article_image_full', // 4096x4096 c-at_max
    comment_image: 'comment_image', // 846x200 c-at_max
    comment_image_full: 'comment_image_full', // 2048x2048 c-at_max
} as const;

export type SizeType = ObjectValues<typeof SIZE>;

const CROP_TYPE = {
    common: 'common',
    cover: 'cover',
} as const;

type CropTypeType = ObjectValues<typeof CROP_TYPE>;

type SizeValueType = { width: number; height: number; cropType: CropTypeType };

const SIZE_VALUES: { [key in SizeType]: SizeValueType } = Object.freeze({
    [SIZE.avatar_xs]: {
        width: 40,
        height: 40,
        cropType: CROP_TYPE.common,
    },
    [SIZE.avatar_sm]: {
        width: 120,
        height: 120,
        cropType: CROP_TYPE.common,
    },
    [SIZE.avatar_lg]: {
        width: 350,
        height: 350,
        cropType: CROP_TYPE.common,
    },
    [SIZE.article_cover]: {
        width: 600,
        height: 338,
        cropType: CROP_TYPE.common,
    },
    [SIZE.article_image]: {
        width: 846,
        height: 700,
        cropType: CROP_TYPE.cover,
    },
    [SIZE.article_image_full]: {
        width: 4096,
        height: 4096,
        cropType: CROP_TYPE.cover,
    },
    [SIZE.comment_image]: {
        width: 846,
        height: 200,
        cropType: CROP_TYPE.cover,
    },
    [SIZE.comment_image_full]: {
        width: 2048,
        height: 2048,
        cropType: CROP_TYPE.cover,
    },
});

type ImageSizesType = {
    width: number;
    height: number;
};

const getRelativeSizeByHeight = (relativeSizes: SizeValueType, imageSizes: ImageSizesType): ImageSizesType => {
    return {
        width: (relativeSizes.height / imageSizes.height) * imageSizes.width,
        height: relativeSizes.height,
    };
};

const getRelativeSizeByWidth = (relativeSizes: SizeValueType, imageSizes: ImageSizesType): ImageSizesType => {
    return {
        width: relativeSizes.width,
        height: (relativeSizes.width / imageSizes.width) * imageSizes.height,
    };
};

const getRelativeSizes = (relativeSizes: SizeValueType, imageSizes: ImageSizesType): ImageSizesType => {
    switch (relativeSizes.cropType) {
        case CROP_TYPE.common:
            return {
                width: relativeSizes.width,
                height: relativeSizes.height,
            };

        case CROP_TYPE.cover:
            if (imageSizes.width > relativeSizes.width && imageSizes.height > relativeSizes.height) {
                if (imageSizes.width > imageSizes.height) {
                    return getRelativeSizeByWidth(relativeSizes, imageSizes);
                }
                return getRelativeSizeByHeight(relativeSizes, imageSizes);
            }
            if (imageSizes.width > relativeSizes.width) {
                return getRelativeSizeByWidth(relativeSizes, imageSizes);
            }
            if (imageSizes.height > relativeSizes.height) {
                return getRelativeSizeByHeight(relativeSizes, imageSizes);
            }
            return imageSizes;
        default:
            return imageSizes;
    }
};

const getSizesByKit = (sizeType: SizeType, imageSizes: ImageSizesType): ImageSizesType => {
    return getRelativeSizes(SIZE_VALUES[sizeType], imageSizes);
};

const IMAGOR_URL = 'https://imagor.network-xyz.com';

const imageKit = (imageUrl: string | undefined, size: SizeType): string | undefined => {
    if (process.env.STORYBOOK_MODE) {
        return imageUrl;
    }
    if (!imageUrl) {
        return undefined;
    }
    if (imageUrl.startsWith(IMAGOR_URL)) {
        return imageUrl;
    }
    if (imageUrl.endsWith('.gif')) {
        return imageUrl;
    }
    return `${IMAGOR_URL}/unsafe/fit-in/${SIZE_VALUES[size].width}x${SIZE_VALUES[size].height}/${imageUrl}`;
};

imageKit.CROP_TYPE = CROP_TYPE;
imageKit.SIZE = SIZE;
imageKit.SIZE_VALUES = SIZE_VALUES;
imageKit.getRelativeSizes = getRelativeSizes;
imageKit.getSizesByKit = getSizesByKit;

export default imageKit;
