// @flow strict
import { useState, useCallback } from 'react';

import { type ListType } from '../List';
import { type NotificationsDataType } from '../NotificationsContent';

const initialState: NotificationsDataType = {
    notifications: [],
    isLoaded: false,
};

const limit = 10;

type ReturnType = {|
    notifications: ListType,
    isOpen: boolean,
    isLoaded: boolean,
    openNotifications: () => Promise<void>,
    closeNotifications: () => void,
    load: () => Promise<void>,
|};

export type useNotificationsDataParamsType = {|
    getNotifications: ({| limit: number |}) => Promise<ListType>,
    reset: () => void,
    makeRead?: (Array<string>) => void,
|};

const useNotificationsData = ({ getNotifications, reset, makeRead }: useNotificationsDataParamsType): ReturnType => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [{ notifications, isLoaded }, setNotifications] = useState<NotificationsDataType>(initialState);

    const load = useCallback(async (): Promise<void> => {
        const data = await getNotifications({ limit });
        setNotifications((prevData) => ({ notifications: [...prevData.notifications, ...data], isLoaded: true }));
    }, [getNotifications]);

    const closeNotifications = useCallback(() => {
        setIsOpen(false);
        setNotifications(initialState);
        reset();
    }, [reset]);

    const openNotifications = useCallback(async () => {
        setIsOpen(true);
        if (!isLoaded) {
            await load();
        }
        if (makeRead) makeRead(notifications.map((item) => item.id));
    }, [isLoaded, load, makeRead, notifications]);

    return {
        notifications,
        isOpen,
        openNotifications,
        closeNotifications,
        load,
        isLoaded,
    };
};

export default useNotificationsData;
