// @flow strict
import React, { type Node } from 'react';

import GridContainer from './GridContainer';
import GridRow from './GridRow';
import GridCol from './GridCol';

type Props = {|
    children: Node,
    containerClassName?: string,
    rowClassName?: string,
|};

const Grid = ({ children, containerClassName, rowClassName }: Props): Node => {
    return (
        <GridContainer className={containerClassName}>
            <GridRow className={rowClassName}>{children}</GridRow>
        </GridContainer>
    );
};

Grid.Item = GridCol;

export default Grid;
