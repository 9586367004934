// @flow strict
export const COMMENT_NOTIFICATION_NAME = {
    article_comment: 'article_comment',
    comment_reply: 'comment_reply',
};

export type CommentNotificationNameType = $Values<typeof COMMENT_NOTIFICATION_NAME>;

export const ARTICLE_NOTIFICATION_NAME = {
    delayed_article_published: 'delayed_article_published',
    delayed_article_error: 'delayed_article_error',
};

export type ArticleNotificationNameType = $Values<typeof ARTICLE_NOTIFICATION_NAME>;

export const SYSTEM_NOTIFICATION_NAME = {
    system: 'system',
};

export type SystemNotificationNameType = $Values<typeof SYSTEM_NOTIFICATION_NAME>;

export const NOTIFICATION_NAME = Object.freeze({
    ...COMMENT_NOTIFICATION_NAME,
    ...ARTICLE_NOTIFICATION_NAME,
    ...SYSTEM_NOTIFICATION_NAME,
});

export type NotificationNameType = $Values<typeof NOTIFICATION_NAME>;
