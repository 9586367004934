// @flow strict
import React, { type Node } from 'react';

import styles from './PageLayoutUI.scss';

type Props = {|
    header: Node,
    children: Node,
|};

const PageLayoutUI = ({ header, children }: Props): Node => {
    return (
        <div className={styles.container}>
            <div className={styles.header}>{header}</div>
            <div className={styles.content}>{children}</div>
        </div>
    );
};

export default PageLayoutUI;
