// @flow strict
import React, { type Node } from 'react';

import { PopoverUi } from '@xyz-school/xyz-react-frontend';

import TriggerButton from '../TriggerButton';

import styles from './NotificationsDesktopView.scss';

type Props = {|
    children: Node,
    count: number,
    isOpen: boolean,
    closeNotifications: () => void,
    openNotifications: () => Promise<void>,
|};

const NotificationsDesktopView = ({ children, count, isOpen, openNotifications, closeNotifications }: Props): Node => {
    const content = <div className={styles.contentWrapper}>{children}</div>;

    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            openNotifications();
        } else {
            closeNotifications();
        }
    };

    return (
        <PopoverUi
            content={content}
            placement="bottomRight"
            color="text-inverted"
            onVisibleChange={onVisibleChange}
            overlayStyle={{ position: 'fixed' }}
            visible={isOpen}
        >
            <TriggerButton count={count} isOpened={isOpen} />
        </PopoverUi>
    );
};

export default NotificationsDesktopView;
