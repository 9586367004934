import React from 'react';

import { Container } from '@xyz-school/xyz-react-frontend';

import AuthModalSocialButtonsItem from '@/features/AuthModal/AuthModalSocialButtonsItem';

import styles from './AuthModalSocialButtons.scss';

type Props = {
    connections: Array<{ provider: string }>;
    onClick: (provider: string) => void;
};

const AuthModalSocialButtons = ({ connections, onClick }: Props) => {
    if (!connections?.length) {
        return null;
    }

    return (
        <Container className={styles.buttonsContainer}>
            {connections.map((socialItem) => (
                <div className={styles.buttonWrapper}>
                    <AuthModalSocialButtonsItem
                        provider={socialItem.provider}
                        onClick={() => onClick(socialItem.provider)}
                    />
                </div>
            ))}
        </Container>
    );
};

export default AuthModalSocialButtons;
