// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Text } from '@xyz-school/xyz-react-frontend';

import { type SimpleUserType, type UserType } from '@/model/User';

import useSubscribe from '../../../hooks/useSubscribe';

import styles from './FollowAction.scss';

type Props = {|
    user: SimpleUserType | UserType,
    isButton?: boolean,
|};

const FollowAction = ({ user, isButton = false }: Props): Node => {
    const { t } = useTranslation(['actions']);
    const { isSubscribed, onClickSubscribe } = useSubscribe({
        isCurrentUserSubscribed: user.isCurrentUserSubscribed,
        id: user.id,
    });
    const label = isSubscribed ? t('UNSUBSCRIBE') : t('SUBSCRIBE');

    if (!(typeof user.isCurrentUserSubscribed === 'boolean')) return null;
    return isButton ? (
        <Button onClick={onClickSubscribe} isFullWidth>
            {label}
        </Button>
    ) : (
        <button onClick={onClickSubscribe} type="button" className={styles.followBtn}>
            <Text size="small" color="inherit">
                {label}
            </Text>
        </button>
    );
};

export default FollowAction;
