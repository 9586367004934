// @flow strict
import React, { type Node } from 'react';

import {
    NotificationProvider,
    I18nProvider,
    LocalizedRouter,
    MediaContextProvider,
    ThemeProvider,
} from '@xyz-school/xyz-react-frontend';

import { setApiLang } from '@/core/api';
import useLocalStorage from '@/core/useLocalStorage';
import YandexHitUrl from '@/core/YandexHitUrl';
import ButtonClickUnfocusHandler from '@/core/ButtonClickUnfocusHandler';
import CurrentUserProvider from '@/model/User/CurrentUserProvider';
import PageLayout from '@/features/PageLayout';
import IconLoader from '@/features/IconLoader';

import AppRoutes from './AppRoutes';
import AppMetaTags from './AppMetaTags';

import styles from './App.scss';

const App = (): Node => {
    const version = process.env.NODE_ENV === 'production' ? __BUILD__ : Date.now();

    const [supportedLngs] = useLocalStorage('langs');

    return (
        <I18nProvider
            supportedLngs={supportedLngs || ['ru']}
            fallbackLng="ru"
            onChange={setApiLang}
            loadPath={`/locales/{{lng}}/{{ns}}.json?v=${version}`}
            useSuspense={false}
            version={`${version}`}
        >
            <ButtonClickUnfocusHandler />
            <NotificationProvider>
                <ThemeProvider>
                    <CurrentUserProvider>
                        <div className={styles.content}>
                            <IconLoader />
                            <LocalizedRouter>
                                <YandexHitUrl />
                                <AppMetaTags />
                                <MediaContextProvider>
                                    <PageLayout>
                                        <AppRoutes />
                                    </PageLayout>
                                </MediaContextProvider>
                            </LocalizedRouter>
                        </div>
                    </CurrentUserProvider>
                </ThemeProvider>
            </NotificationProvider>
        </I18nProvider>
    );
};

export default App;
