import React, { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { Icon } from '@xyz-school/xyz-react-frontend';

import { scrollToTop } from '@/core/scrollToTop';

import styles from './ScrollToTopButton.scss';

const ScrollToTopButton = () => {
    const prevScrollPosition = useRef(0);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;
            setIsVisible(currentScrollPos !== 0 && prevScrollPosition.current > currentScrollPos);
            prevScrollPosition.current = currentScrollPos;
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.addEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScrollToTop = useCallback(() => {
        scrollToTop();
    }, []);

    return (
        <button
            onClick={handleScrollToTop}
            type="button"
            className={classnames(styles.button, {
                [styles.button__hidden]: !isVisible,
            })}
        >
            <Icon name="arrow-thick" />
        </button>
    );
};

export default ScrollToTopButton;
