// @flow
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Container, Heading, Text } from '@xyz-school/xyz-react-frontend';

import styles from './BetaFeedbackForm.scss';

const BetaFeedbackForm = () => {
    const { t } = useTranslation(['feedbackForm']);

    return (
        <div className={styles.container}>
            <Container marginBottom={2} marginBottomSm={1}>
                <Heading level={3}>{t('BETA_FEEDBACK_TITLE')}</Heading>
            </Container>
            <Container marginBottom={2} marginBottomSm={2}>
                <Text size="small" color="label">
                    {t('BETA_FEEDBACK_DESCRIPTION')}
                </Text>
            </Container>
            <Button linkTo="https://forms.gle/i1RB7JrY71jnZNL59" linkTarget="_blank">
                {t('BETA_FEEDBACK_SUBMIT')}
            </Button>
        </div>
    );
};
export default BetaFeedbackForm;
