// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import { textConvertToMultiLine } from '@xyz-school/xyz-react-frontend/lib/utils/text';

import styles from './Text.scss';

export type TextColorType =
    | 'inherit'
    | 'text'
    | 'text-inverted'
    | 'label'
    | 'input'
    | 'accent'
    | 'hover'
    | 'success'
    | 'warning'
    | 'fail'
    | 'accent-lite'
    | 'success-lite'
    | 'warning-lite'
    | 'fail-lite';

export const SIZE = Object.freeze({
    inherit: 'inherit',
    paragraph: 'paragraph',
    label: 'label',
    counter: 'counter',
});

export type TextSizeType = $Values<typeof SIZE>;

type Props = {|
    tag?: 'p' | 'div' | 'span' | 'label' | 'cite',
    htmlFor?: string,
    children?: Node,
    /**
     * Text align
     */
    align?: 'left' | 'center' | 'right',
    /**
     * Text font size
     */
    size?: TextSizeType,
    /**
     * Text color
     */
    color?: TextColorType,
    /**
     * Use span element to render text inline
     */
    inline?: boolean,
    /**
     * Custom styles
     */
    className?: string,
    wrap?: boolean,
    multiLine?: boolean,
|};

const Text = ({
    className,
    tag = 'p',
    htmlFor,
    children,
    align = 'left',
    size = SIZE.paragraph,
    color = 'text',
    inline = false,
    wrap = false,
    multiLine = false,
}: Props): Node => {
    let Tag = tag;
    if (inline) Tag = 'span';

    return (
        <Tag
            htmlFor={htmlFor}
            className={classnames(styles.text, className, {
                [styles[`text-color--${String(color)}`]]: color,
                [styles[`text-size--${String(size)}`]]: size,
                [styles[`text-align--${String(align)}`]]: align !== 'left',
                [styles['text-wrap']]: wrap,
            })}
        >
            {multiLine ? textConvertToMultiLine(children) : children}
        </Tag>
    );
};

Text.size = SIZE;

export default Text;
