import { useCallback, useEffect, useMemo, useState } from 'react';

type UseLocalStorageReturnType<T = {} | boolean> = [T | undefined, (item: T | undefined) => void];

const storage = window.localStorage || { getItem: () => null, removeItem: () => {}, setItem: () => {} };

const useLocalStorage = <T = {} | boolean>(name: string): UseLocalStorageReturnType<T> => {
    const initialValue = useMemo(() => {
        const item = storage.getItem(name);
        if (item) {
            return JSON.parse(item);
        }
        return null;
    }, [name]);
    const [localValue, setLocalValue] = useState<T | undefined>(initialValue);

    useEffect(() => {
        setLocalValue(initialValue);
    }, [initialValue]);

    const setValue = useCallback(
        (data) => {
            if (!data) {
                storage.removeItem(name);
                setLocalValue(undefined);
            } else {
                storage.setItem(name, JSON.stringify(data));
                setLocalValue(data);
            }
        },
        [setLocalValue, name],
    );
    return [localValue, setValue];
};

export default useLocalStorage;
