export const scrollToTop = () => {
    const rootElement = document.documentElement;

    if (!rootElement) {
        return;
    }

    rootElement.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};
