// @flow strict
import React, { type Node } from 'react';

import { Portal, ThemeProvider, ClickOutside } from '@xyz-school/xyz-react-frontend';

import Grid from '@/features/Grid';
import Icon from '@/ui/Icon';
import SidebarItem from './SidebarItem';

import styles from './Sidebar.scss';

type Props = {|
    children: Node | Node[],
    footer?: Node,
    onClose: () => void,
|};

const Sidebar = ({ children, footer, onClose }: Props): Node => {
    return (
        <Portal>
            <ThemeProvider>
                <div className={styles.wrapper}>
                    <Grid rowClassName={styles.gridRow}>
                        <Grid.Item xl={4} lg={4} md={4} sm={4}>
                            <ClickOutside onClickOutside={onClose}>
                                <div className={styles.container}>
                                    {!!onClose && (
                                        <button type="button" onClick={onClose} className={styles.closeButton}>
                                            <Icon name="close-media" color="text" />
                                        </button>
                                    )}
                                    <div>{children}</div>
                                    {!!footer && <div className={styles.footer}>{footer}</div>}
                                </div>
                            </ClickOutside>
                        </Grid.Item>
                    </Grid>
                </div>
            </ThemeProvider>
        </Portal>
    );
};

Sidebar.Item = SidebarItem;

export default Sidebar;
