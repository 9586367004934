// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import { Link } from '@xyz-school/xyz-react-frontend';

import Text from '@/typography/Text';

import styles from './ArticleTopicsItem.scss';

type Props = {
    label: string,
    value: string,
    icon?: string,
    to: string,
    isChecked: boolean,
    isBorderBottom?: boolean,
    isBorderTop?: boolean,
    className?: string,
};

const ArticleTopicsItem = ({
    label,
    value,
    icon,
    to,
    isChecked,
    isBorderBottom,
    isBorderTop,
    className,
}: Props): Node => {
    return (
        <Link
            to={to}
            history
            className={classnames(styles.container, className, {
                [styles.container__isChecked]: isChecked,
                [styles.container__isBorderBottom]: isBorderBottom,
                [styles.container__isBorderTop]: isBorderTop,
            })}
        >
            {!!icon && (
                <div className={styles.iconContainer}>
                    <img className={styles.image} src={icon} alt={value} />
                </div>
            )}
            <Text className={styles.label}>{label}</Text>
        </Link>
    );
};

export default ArticleTopicsItem;
