import React, { ReactNode } from 'react';

// @ts-ignore
import { Portal } from '@xyz-school/xyz-react-frontend';

import Grid from '@/features/Grid';

import styles from './PageBottomContainer.scss';

type Props = {
    children: ReactNode;
};

const PageBottomContainer = ({ children }: Props) => {
    return (
        <Portal>
            <div className={styles.wrapper}>
                <Grid>
                    <Grid.Item className={styles.container}>{children}</Grid.Item>
                </Grid>
            </div>
        </Portal>
    );
};

export default PageBottomContainer;
