// @flow strict

import React from 'react';
import { type CurrentUserType, type UserSettingsType } from '@/model/User';

import { EMPTY_USER_SETTINGS } from './settings';

export type CurrentUserContextType = {|
    isLogin: boolean,
    isLoaded: boolean,
    currentUser?: ?CurrentUserType,
    settings: UserSettingsType,
    updateUser: (?CurrentUserType | ((?CurrentUserType) => CurrentUserType)) => void,
|};

export const CurrentUserContext: React$Context<CurrentUserContextType> = React.createContext({
    isLogin: false,
    isLoaded: false,
    settings: EMPTY_USER_SETTINGS,
    updateUser: () => {},
});
