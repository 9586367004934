// @flow strict
import React, { type Node, useEffect } from 'react';
import { useLanguage } from '@xyz-school/xyz-react-frontend';
import { useTheme } from '@xyz-school/xyz-react-frontend/lib/theme';

import * as yandexMetrika from '@/core/yandexMetrika';

import useReloadOnUserChange from './useReloadOnUserChange';
import { CurrentUserContext } from './CurrentUserContext';
import useLoadSettings from './useLoadSettings';
import useLoadUser from './useLoadUser';
import useHandleApiForbiddenError from './useHandleApiForbidenError';

const CurrentUserProvider = ({ children }: {| children: Node |}): Node => {
    const { currentLang } = useLanguage();
    const [{ settings }, { loadSettings }] = useLoadSettings();
    const [{ user: currentUser, isLoading }, { loadUser, updateUser }] = useLoadUser();
    const { globalTheme } = useTheme();

    useReloadOnUserChange();
    useHandleApiForbiddenError({ updateUser });
    useEffect(() => {
        loadUser();
        loadSettings();
    }, [currentLang]);

    useEffect(() => {
        if (currentUser) {
            yandexMetrika.setUserID(currentUser.id);
            yandexMetrika.userParams({
                isLogin: true,
                role: currentUser.journalAccess.label,
                theme: globalTheme,
            });
        } else {
            yandexMetrika.setUserID(undefined);
            yandexMetrika.userParams({
                isLogin: false,
                theme: globalTheme,
            });
        }
    }, [currentUser, globalTheme]);

    return (
        <CurrentUserContext.Provider
            value={{
                isLogin: !!currentUser,
                isLoaded: !isLoading,
                currentUser,
                updateUser,
                settings,
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};

CurrentUserProvider.Context = CurrentUserContext;

export default CurrentUserProvider;
