import { useState, useEffect, useCallback } from 'react';
import createEvent from '@/core/createEvent';

const EVENT_NAME = 'onClickSubscribe' as const;

type EventType = { detail: { isSubscribed: boolean } };

const useUpdateSubscribers = (
    userSubscribersCount: number = 0,
): {
    createSubscriptionEvent: (isSubscribed: boolean) => void;
    subscribersCount: number;
} => {
    const [subscribersCount, setSubscribersCount] = useState<number>(userSubscribersCount);

    const createSubscriptionEvent = (isSubscribed: boolean) => {
        createEvent({
            name: EVENT_NAME,
            detail: { isSubscribed },
        });
    };

    const handleSubscribersCount = useCallback((event): void => {
        setSubscribersCount((prevState) => {
            return (event as EventType).detail.isSubscribed ? prevState + 1 : prevState - 1;
        });
    }, []);

    useEffect(() => {
        window.addEventListener(EVENT_NAME, handleSubscribersCount);
        return () => {
            window.removeEventListener(EVENT_NAME, handleSubscribersCount);
        };
    }, [handleSubscribersCount]);

    useEffect(() => {
        setSubscribersCount(userSubscribersCount);
    }, [userSubscribersCount]);

    return { createSubscriptionEvent, subscribersCount };
};

export default useUpdateSubscribers;
