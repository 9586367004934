// @flow strict
import React, { type Node, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Container } from '@xyz-school/xyz-react-frontend';

import { useMedia } from '@/core/media';
import { hideGlobalScrollbar, showGlobalScrollbar } from '@/core/scrollbarHelper';
import SidebarTopics from '@/features/SidebarTopics';

import Icon from '@/ui/Icon';

import styles from './HeaderTopicsMenu.scss';

type Props = {|
    isTopicsMenuOpened: boolean,
    setIsTopicsMenuOpened: (boolean) => void,
|};

const HeaderTopicsMenu = ({ isTopicsMenuOpened, setIsTopicsMenuOpened }: Props): Node => {
    const location = useLocation();

    const { sm, xs } = useMedia();
    const isMobile = xs;
    const isTablet = sm;

    useEffect(() => {
        setIsTopicsMenuOpened(false);
        showGlobalScrollbar();
    }, [location.pathname]);

    const handleTopicsMenuClose = useCallback(() => {
        setIsTopicsMenuOpened(false);
        if (isMobile) {
            showGlobalScrollbar();
        }
    }, [isMobile]);

    const handleTopicsMenuOpen = useCallback(() => {
        setIsTopicsMenuOpened(true);
        if (isMobile) {
            hideGlobalScrollbar();
        }
    }, [isMobile]);

    if (!isMobile && !isTablet) {
        return null;
    }

    return (
        <>
            <Container marginLeft={2}>
                <Icon name="menu-media" color="text" onClick={handleTopicsMenuOpen} className={styles.icon} />
            </Container>
            {isTopicsMenuOpened && <SidebarTopics onSidebarTopicsClose={handleTopicsMenuClose} />}
        </>
    );
};

export default HeaderTopicsMenu;
