// @flow strict
import { useCallback } from 'react';

import { useQueryUrl } from './useQueryUrl';

type CallbackType = (?string) => void;

/**
 * Get current param from url and change it in location search
 */
export const useQueryParam = (paramName: string, defaultValue: ?string): [?string, CallbackType] => {
    const { params, setQuery } = useQueryUrl();
    const param = typeof params[paramName] === 'string' ? params[paramName] : defaultValue;

    const setParam = useCallback(
        (value) => {
            setQuery({ [paramName]: value === defaultValue ? '' : value });
        },
        [defaultValue, setQuery],
    );

    return [param, setParam];
};
