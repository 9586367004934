// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import styles from './GridCol.scss';

type Props = {|
    /**
     * Extra-width Desktop
     * 16 columns
     * Default: 16
     */
    xl?: number,
    /**
     * Desktop
     * 16 columns
     * Default: 16
     */
    lg?: number,
    /**
     * Tablet
     * 12 columns
     * Default: 12
     */
    md?: number,
    /**
     * Big mobile
     * 8 columns
     * Default: 8
     */
    sm?: number,
    /**
     * Mobile
     * 4 columns
     * Default: 4
     */
    xs?: number,
    /**
     * Offset for extra-width Desktop
     * 16 columns
     * Default: none
     */
    offsetXl?: number,
    /**
     * Offset for desktop
     * 16 columns
     * Default: none
     */
    offsetLg?: number,
    /**
     * Offset for tablet
     * 12 columns
     * Default: none
     */
    offsetMd?: number,
    /**
     * Offset for big mobile
     * 8 columns
     * Default: none
     */
    offsetSm?: number,
    /**
     * Offset for mobile
     * 4 columns
     * Default: none
     */
    offsetXs?: number,
    children?: Node,
    className?: string,
|};

const GridCol = ({
    xl = 16,
    lg = 16,
    md = 12,
    sm = 8,
    xs = 4,
    offsetXl = 0,
    offsetLg = 0,
    offsetMd = 0,
    offsetSm = 0,
    offsetXs = 0,
    children,
    className,
}: Props): Node => {
    return (
        <div
            className={classnames(styles.col, className, {
                [styles[`col_xl--${xl}`]]: xl,
                [styles[`col_lg--${lg}`]]: lg,
                [styles[`col_md--${md}`]]: md,
                [styles[`col_sm--${sm}`]]: sm,
                [styles[`col_xs--${xs}`]]: xs,
                [styles[`col_offset_xl--${offsetXl}`]]: offsetXl,
                [styles[`col_offset_lg--${offsetLg}`]]: offsetLg,
                [styles[`col_offset_md--${offsetMd}`]]: offsetMd,
                [styles[`col_offset_sm--${offsetSm}`]]: offsetSm,
                [styles[`col_offset_xs--${offsetXs}`]]: offsetXs,
            })}
        >
            {children}
        </div>
    );
};

export default GridCol;
