// @flow
import React, { type Node } from 'react';
import classnames from 'classnames';

import styles from './Icon.scss';

export type IconSizeType = 'small' | 'medium' | 'large' | 'extra-large' | 'largest' | 'inherit';
export type IconColorType =
    | 'text'
    | 'text-inverted'
    | 'label'
    | 'icon'
    | 'border'
    | 'input'
    | 'background'
    | 'accent'
    | 'success'
    | 'warning'
    | 'fail'
    | 'accent-lite'
    | 'success-lite'
    | 'warning-lite'
    | 'fail-lite'
    | 'inherit';

type Props = {
    /**
     * Name of the Icon, ie: `like`
     */
    name: string,
    /**
     * Icon size
     */
    size?: IconSizeType | null,
    /**
     * Icon color
     */
    color?: IconColorType,
    /**
     * Custom classname
     */
    className?: string,
    /**
     * Custom xlinkHref
     */
    xlinkHref?: string,
    onClick?: () => void,
};

const Icon = ({ name, size = 'medium', color = 'icon', className, xlinkHref, onClick }: Props): Node => {
    return (
        <svg
            className={classnames(styles.icon, className, styles[`color-${String(color)}`], {
                // $FlowFixMe: flow didn't detect that we're checking for null here
                [styles[`size-${String(size)}`]]: size !== null,
            })}
            focusable={false}
            onClick={onClick}
        >
            <use xlinkHref={xlinkHref || `#icon-${name}`} />
        </svg>
    );
};

export default Icon;
