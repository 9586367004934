// @flow strict

import { useEffect } from 'react';

import { axios } from '@/core/api';

import useLocalStorage from '@/core/useLocalStorage';

import { STORAGE_IS_LOGIN_KEY } from './settings';

const useHandleApiForbiddenError = ({ updateUser }: { updateUser: () => void }) => {
    const [, updateIsLoginInStorage] = useLocalStorage<boolean>(STORAGE_IS_LOGIN_KEY);
    useEffect(() => {
        const interceptor = axios.interceptors.response.use(
            (r) => r,
            (error) => {
                if (error && error.response && error.response.status === 401) {
                    updateIsLoginInStorage(false);
                    updateUser();
                }
                return Promise.reject(error);
            },
        );
        return () => {
            axios.interceptors.request.eject(interceptor);
        };
    }, [axios, updateIsLoginInStorage]);
};

export default useHandleApiForbiddenError;
