// @flow strict
import React, { type Node, useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useQueryParam } from '@/core/query';
import Icon from '@/ui/Icon';
import Text from '@/typography/Text';

import styles from './HeaderSearch.scss';

type Props = {|
    isOpened?: boolean,
    onSubmit: (query: string) => void,
    onClose: () => void,
|};

const HeaderSearch = ({ isOpened = true, onSubmit, onClose }: Props): Node => {
    const { t } = useTranslation('header');
    const [searchQuery, setSearchQuery] = useQueryParam('q');
    const [value, onChange] = useState(searchQuery || '');
    const inputRef = useRef<?HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, [isOpened]);

    const handleBlur = useCallback((): void => {
        if (!value) onClose();
    }, [value]);

    const handleSubmit = useCallback(
        (e): void => {
            e.preventDefault();
            setSearchQuery(value);
            onSubmit(value);
        },
        [value],
    );

    const handleClose = useCallback((): void => {
        onSubmit('');
        onClose();
    }, []);

    const handleKeyDown = useCallback((e): void => {
        if (e.key === 'Escape') handleClose();
    }, []);

    const handleChange = useCallback((e): void => {
        onChange(e.currentTarget.value);
    }, []);

    if (!isOpened) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit} className={styles.searchWrap}>
            <div className={styles.searchIconWrap}>
                <Icon name="search-media" />
            </div>
            <input
                value={value}
                placeholder={t('SEARCH_PLACEHOLDER')}
                ref={inputRef}
                className={styles.searchInput}
                type="text"
                onKeyDown={handleKeyDown}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <div className={classnames(styles.searchHintWrap, { [styles.isHidden]: !value })}>
                <button type="submit" className={styles.searchHint}>
                    <Text color="label">{t('SEARCH_HINT')}</Text>
                    <Icon className={styles.searchHintIcon} name="enter-media" />
                </button>
            </div>
            <div className={styles.searchCloseWrap}>
                <button className={styles.searchCloseButton} type="button" onClick={handleClose}>
                    <Icon color="text" name="close-media" />
                </button>
            </div>
        </form>
    );
};

export default HeaderSearch;
