// @flow strict

import { useEffect } from 'react';

import { STORAGE_IS_LOGIN_KEY } from './settings';

/**
 * Автоматическое обновление фонов вкладок, если вы вошли или вышли в другой вкладке
 */
const useReloadOnUserChange = () => {
    useEffect(() => {
        const handler = ({ key, oldValue, newValue }) => {
            if (key === STORAGE_IS_LOGIN_KEY) {
                const isUserChanged = oldValue !== newValue;
                const isUserLoginOrLogOut = !oldValue || !newValue;
                if (isUserChanged && isUserLoginOrLogOut && document.hidden) {
                    window.location.reload();
                }
            }
        };
        window.addEventListener('storage', handler);

        return () => {
            window.removeEventListener('storage', handler);
        };
    }, []);
};

export default useReloadOnUserChange;
