// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@xyz-school/xyz-react-frontend/lib/theme';
import { Link } from '@xyz-school/xyz-react-frontend';

import { useFeatures } from '@/model/User/CurrentUserProvider';
import { Media, MediaContextProvider } from '@/core/media';
import { routes } from '../../../journal/constants/urls';

import logo from '../assets/logo.svg';
import logoDark from '../assets/darkThemelogo.svg';

import styles from './HeaderLogo.scss';

const HeaderLogo = (): Node => {
    const { t } = useTranslation('header');
    const { currentTheme } = useTheme();
    const { beta } = useFeatures();

    return (
        <Link to={routes.HOME} className={styles.logoWrap}>
            <img src={currentTheme === 'light' ? logo : logoDark} alt="" className={styles.logo} />
            <MediaContextProvider>
                <Media greaterThanOrEqual="sm">
                    <div
                        className={classnames(styles.siteName, {
                            [styles.beta]: beta,
                        })}
                    >
                        {t('header:SITE_NAME')}
                    </div>
                </Media>
            </MediaContextProvider>
        </Link>
    );
};
export default HeaderLogo;
