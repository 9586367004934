// @flow strict
import React, { type Node } from 'react';

import Sidebar from '@/ui/Sidebar';
import { useCurrentUser } from '@/model/User';
import MediaSocialExternalLinks from '@/features/MediaSocialExternalLinks';
import { useMedia } from '@/core/media/useMedia';
import GlobalThemeToggleSwitch from '@/features/GlobalThemeToggleSwitch';
import BetaFeedbackForm from '@/features/BetaFeedbackForm';
import SidebarMenuUser from './SidebarMenuUser';
import SidebarMenuNavigation from './SidebarMenuNavigation';
import SidebarMenuExternalLinks from './SidebarMenuExternalLinks';
import SidebarMenuFooter from './SidebarMenuFooter';

type Props = {|
    onSidebarMenuClose: () => void,
|};

const SidebarMenu = ({ onSidebarMenuClose }: Props): Node => {
    const currentUser = useCurrentUser();
    const isAuth = !!currentUser && !!Object.keys(currentUser).length;

    const { md, sm, xs } = useMedia();
    const isMobile = xs;
    const isTablet = sm;
    const isSmallDesktop = md;

    return (
        <Sidebar footer={<SidebarMenuFooter />} onClose={onSidebarMenuClose}>
            {isAuth && (
                <Sidebar.Item>
                    <SidebarMenuUser
                        id={currentUser?.id}
                        avatar={currentUser?.avatar}
                        username={currentUser?.username}
                    />
                </Sidebar.Item>
            )}
            <Sidebar.Item>
                <SidebarMenuNavigation isAuth={isAuth} currentUserId={currentUser?.id} />
            </Sidebar.Item>
            {isAuth && !isMobile && (
                <Sidebar.Item>
                    <GlobalThemeToggleSwitch />
                </Sidebar.Item>
            )}
            <Sidebar.Item>
                <SidebarMenuExternalLinks />
            </Sidebar.Item>
            <Sidebar.Item>
                <MediaSocialExternalLinks />
            </Sidebar.Item>
            {(isSmallDesktop || isTablet || isMobile) && (
                <Sidebar.Item>
                    <BetaFeedbackForm />
                </Sidebar.Item>
            )}
        </Sidebar>
    );
};

export default SidebarMenu;
