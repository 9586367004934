// @flow strict
import React, { Suspense, lazy, type Node } from 'react';
import { Route, Redirect, Switch, useRouteMatch, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';

import { PageError404, PageError500 } from '@xyz-school/xyz-react-frontend';

import { routes } from './constants/urls';

const PageHome = lazy(() => import(/* webpackChunkName: "PageHome" */ '../pages/PageHome'));
const PageSearch = lazy(() => import(/* webpackChunkName: "PageSearch" */ './components/PageSearch'));
const PageProfile = lazy(() => import(/* webpackChunkName: "PageProfile" */ '../pages/PageProfile'));
const PageFavorites = lazy(() => import(/* webpackChunkName: "PageFavorites" */ '../pages/PageFavorites'));
const PageSubscriptions = lazy(() => import(/* webpackChunkName: "PageSubscriptions" */ '../pages/PageSubscriptions'));
const PageArticleCreate = lazy(() => import(/* webpackChunkName: "PageArticleCreate" */ '../pages/PageArticleCreate'));
const PageArticleEdit = lazy(() => import(/* webpackChunkName: "PageArticleEdit" */ '../pages/PageArticleEdit'));
const PageArticleView = lazy(() => import(/* webpackChunkName: "PageArticleView" */ '../pages/PageArticleView'));
const PageInfo = lazy(() => import(/* webpackChunkName: "PageArticleView" */ '../pages/PageInfo'));

const AppRoutes = (): Node => {
    const { path } = useRouteMatch();
    const location = useLocation();

    return (
        <ErrorBoundary fallback={<PageError500 />}>
            <Suspense fallback={<div />}>
                <Switch>
                    <Redirect
                        exact
                        from="/:url*(/+)"
                        to={{
                            ...location,
                            pathname: location.pathname.slice(0, -1),
                        }}
                    />
                    <Route exact path={`${path}${routes.HOME}`}>
                        {() => (
                            <Suspense fallback={<div />}>
                                <PageHome />
                            </Suspense>
                        )}
                    </Route>
                    <Route exact path={`${path}${routes.TOPIC(':topicSlug')}`}>
                        {() => (
                            <Suspense fallback={<div />}>
                                <PageHome />
                            </Suspense>
                        )}
                    </Route>
                    <Route exact path={`${path}${routes.BOOKMARKS}`}>
                        {() => (
                            <Suspense fallback={<div />}>
                                <PageFavorites />
                            </Suspense>
                        )}
                    </Route>
                    <Route exact path={`${path}${routes.SUBSCRIPTIONS}`}>
                        {() => (
                            <Suspense fallback={<div />}>
                                <PageSubscriptions />
                            </Suspense>
                        )}
                    </Route>
                    <Route exact path={`${path}/articles/editor`}>
                        {() => (
                            <Suspense fallback={<div />}>
                                <PageArticleCreate />
                            </Suspense>
                        )}
                    </Route>
                    <Route exact path={`${path}/articles/editor/:id`}>
                        {({ match }) =>
                            match && (
                                <Suspense fallback={<div />}>
                                    <PageArticleEdit id={String(match.params.id)} />
                                </Suspense>
                            )
                        }
                    </Route>
                    <Route path={`${path}${routes.SEARCH}`}>{() => <PageSearch />}</Route>
                    <Route exact path={`${path}${routes.ARTICLE(':slug')}`}>
                        {({ match }) =>
                            match && (
                                <Suspense fallback={<div />}>
                                    <PageArticleView slug={String(match.params.slug)} />
                                </Suspense>
                            )
                        }
                    </Route>
                    <Route exact path={`${path}${routes.PROFILE(':id')}`}>
                        {({ match }) =>
                            match && (
                                <Suspense fallback={<div />}>
                                    <PageProfile id={String(match.params.id)} />
                                </Suspense>
                            )
                        }
                    </Route>
                    <Route path={`${path}${routes.INFO(':slug')}`}>
                        {({ match }) => match && <PageInfo slug={String(match.params.slug)} />}
                    </Route>
                    <Route path="*">{() => <PageError404 />}</Route>
                </Switch>
            </Suspense>
        </ErrorBoundary>
    );
};

export default AppRoutes;
