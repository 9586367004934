// @flow
import { useMemo, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import pickBy from 'lodash/pickBy';

import { useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

import { queryParse, queryStringify, type queryParams } from './query';

type SetQuery = (newParams: queryParams) => void;

type GetQueryType = (queryParams) => string;

type UseQuery = {
    params: queryParams,
    setQuery: SetQuery,
    getQuery: GetQueryType,
};

export const getQueryUtil = (oldParams: queryParams): GetQueryType => (newParams): string => {
    const mergedParams = { ...oldParams, ...newParams };
    const filteredParams = pickBy(mergedParams, (value) => !!value);
    const newQuery = queryStringify(filteredParams);
    return newQuery ? `?${newQuery}` : '';
};

/**
 * Returns params parsed from query and setQuery function.
 * Needs to be used inside Router.
 */
export function useQueryUrl(): UseQuery {
    const location = useLocation();
    const history = useLocalizedHistory();
    const params = useMemo(() => queryParse(location.search.slice(1)), [location]);

    const getQuery = useCallback(getQueryUtil(params), [params]);

    const setQuery = useCallback((newParams: queryParams) => {
        const url = getQuery(newParams);
        history.push(url || location.pathname);
    }, []);

    return {
        params: useMemo(() => params, [params]),
        setQuery,
        getQuery,
    };
}

export const getString = (value: mixed): string => (typeof value === 'string' ? value : '');
