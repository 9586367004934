import React from 'react';
import { useLocation } from 'react-router-dom';

import { useLocalizedHistory } from '@xyz-school/xyz-react-frontend';

// @ts-ignore
// eslint-disable-next-line import/named
import { useCurrentUserSettings } from '@/model/User/CurrentUserProvider';

import AuthModalForm from '@/features/AuthModal/AuthModalForm';

const AuthModal = () => {
    const location = useLocation<{ authModal: boolean }>();
    const history = useLocalizedHistory();
    const { dictionaries } = useCurrentUserSettings();

    const handleClose = () => {
        history.replace({
            ...location,
            state: {
                ...(location.state || {}),
                authModal: false,
            },
        });
    };

    const handleSocialButtonClick = (provider: string) => {
        window.location.assign(`/xyz-network/login/${provider}?next=${location.pathname}`);
    };

    if (!location.state?.authModal) {
        return null;
    }

    return (
        <AuthModalForm
            connections={dictionaries.connections}
            onConnectionClick={handleSocialButtonClick}
            onClose={handleClose}
        />
    );
};

export default AuthModal;
