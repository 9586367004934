// @flow
import qs from 'qs';

// This file serves purpose to config qs module

export type queryParams = {
    [string]: mixed,
};

export function queryParse(value: string): queryParams {
    const config = {};
    return qs.parse(value, config);
}

export function queryStringify(value: queryParams): string {
    const config = {};
    return qs.stringify(value, config);
}
