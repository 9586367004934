// @flow strict
import React, { type Node } from 'react';

import { Media, MediaContextProvider } from '@/core/media';
import MobileView from './MobileView';
import NotificationsDesktopView from './NotificationsDesktopView';
import NotificationsContent from './NotificationsContent';
import useNotificationsData, { type useNotificationsDataParamsType } from './useNotificationsData';

type Props = {|
    getNotifications: $PropertyType<useNotificationsDataParamsType, 'getNotifications'>,
    makeRead?: $PropertyType<useNotificationsDataParamsType, 'makeRead'>,
    count: number,
    reset: $PropertyType<useNotificationsDataParamsType, 'reset'>,
    isNext: boolean,
|};

const Notifications = ({ getNotifications, count, makeRead, reset, isNext }: Props): Node => {
    const { notifications, isOpen, isLoaded, load, openNotifications, closeNotifications } = useNotificationsData({
        getNotifications,
        reset,
        makeRead,
    });

    const content = (
        <NotificationsContent
            notifications={notifications}
            isLoaded={isLoaded}
            load={load}
            isNext={isNext}
            closeNotifications={closeNotifications}
        />
    );

    return (
        <MediaContextProvider>
            <Media lessThan="lg">
                <MobileView
                    count={count}
                    isOpen={isOpen}
                    openNotifications={openNotifications}
                    closeNotifications={closeNotifications}
                >
                    {content}
                </MobileView>
            </Media>
            <Media greaterThanOrEqual="lg">
                <NotificationsDesktopView
                    count={count}
                    isOpen={isOpen}
                    openNotifications={openNotifications}
                    closeNotifications={closeNotifications}
                >
                    {content}
                </NotificationsDesktopView>
            </Media>
        </MediaContextProvider>
    );
};

export default Notifications;
