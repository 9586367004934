// @flow strict
import React, { type Node } from 'react';

import Icon from '@/ui/Icon';

import styles from './HeaderControls.scss';

type Props = {|
    onSearchClick: () => void,
    notifications: Node,
    userMenu: Node,
    topicsMenu: Node,
|};

const HeaderControls = ({ onSearchClick, notifications, userMenu, topicsMenu }: Props): Node => {
    return (
        <div className={styles.container}>
            <div className={styles.iconsContainer}>
                <Icon name="search-media" onClick={onSearchClick} className={styles.icon} />
                {!!notifications && <div className={styles.notificationsContainer}>{notifications}</div>}
            </div>
            <div className={styles.userContainer}>
                {userMenu}
                {topicsMenu}
            </div>
        </div>
    );
};

export default HeaderControls;
