// @flow strict
import React, { type Node } from 'react';
import { useLocation } from 'react-router-dom';

import { Container, useLanguage } from '@xyz-school/xyz-react-frontend';

import ArticleTopics from '@/features/ArticleTopics';
import Sidebar from '@/ui/Sidebar';
import { useCurrentUserSettings } from '@/model/User/CurrentUserProvider';
import useFilter from '../../pages/PageHome/useFilter';

type Props = {|
    onSidebarTopicsClose: () => void,
|};

const SidebarTopics = ({ onSidebarTopicsClose }: Props): Node => {
    // eslint-disable-next-line no-unused-vars
    const [filter, setFilter, getUrl] = useFilter();

    const { pathname } = useLocation();
    const { dictionaries } = useCurrentUserSettings();

    const { toLocalizedRoute } = useLanguage();

    const getTopicUrl = (newTopic: string): string => {
        return getUrl({ topic: newTopic });
    };

    return (
        <Sidebar onClose={onSidebarTopicsClose}>
            <Sidebar.Item>
                <Container marginTop={3}>
                    <ArticleTopics
                        topics={dictionaries.topics}
                        currentTopic={pathname.startsWith(toLocalizedRoute('/topic')) ? pathname.split('/').pop() : ''}
                        getTopicUrl={getTopicUrl}
                    />
                </Container>
            </Sidebar.Item>
        </Sidebar>
    );
};

export default SidebarTopics;
