// @flow strict
import { useState, useEffect } from 'react';

const ANIMATION_PENDING = 300;

const useLoading = (isLoading: boolean): boolean => {
    const [isLoadingShow, setIsLoadingShow] = useState<boolean>(false);
    const [isAnimationPending, setIsAnimationPending] = useState<boolean>(false);
    const [timeoutId, setTimeoutId] = useState();

    useEffect(() => {
        // case of switching on
        if (!isLoadingShow && isLoading) {
            setIsLoadingShow(true);
            setIsAnimationPending(true);
            setTimeoutId(setTimeout(() => setIsAnimationPending(false), ANIMATION_PENDING));
        }
        // case of switching of
        if (isLoadingShow && !isLoading && !isAnimationPending) {
            setIsLoadingShow(false);
        }
    }, [isLoading, isAnimationPending]);

    useEffect(() => {
        return () => clearTimeout(timeoutId);
    }, []);

    return isLoadingShow;
};

export default useLoading;
