// @flow strict
import React, { type Node, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { type HeaderNotificationUiType } from '@xyz-school/xyz-react-frontend';

import { useNotificationsApi, getNotificationUiData } from '@/model/Notification';
import { useIsLogin } from '@/model/User/CurrentUserProvider';
import Notifications from '@/features/Header/Notifications';

const NotificationsWrapper = (): Node => {
    const isLogin = useIsLogin();
    const { fetchApi, resetCursor, isNext, unreadCount } = useNotificationsApi();
    const { t } = useTranslation(['headerNotifications']);
    const getNotifications = useCallback(
        async ({ limit }: { limit: number }): Promise<Array<HeaderNotificationUiType>> => {
            // $FlowFixMe
            const data = await fetchApi(limit);
            // $FlowFixMe
            return data.map(getNotificationUiData(t)).filter((item) => item);
        },
        [fetchApi, getNotificationUiData],
    );

    if (!isLogin) {
        return null;
    }

    return (
        <Notifications count={unreadCount} getNotifications={getNotifications} reset={resetCursor} isNext={isNext} />
    );
};

export default NotificationsWrapper;
