// @flow strict
import React, { type Node } from 'react';

import Icon from '@/ui/Icon';
import NotificationCounterCircle from '@/ui/NotificationCounterCircle';

import styles from './TriggerButton.scss';

type Props = {|
    count: number,
    onClick?: () => void | Promise<void>,
    isOpened?: boolean,
|};

const TriggerButton = ({ count, onClick, isOpened }: Props): Node => {
    return (
        <button type="button" className={styles.button} onClick={onClick}>
            <div className={styles.wrapper}>
                <Icon name="bell-media" color={!isOpened ? 'icon' : 'accent'} />
                {count ? (
                    <div className={styles.counter}>
                        <NotificationCounterCircle>{count}</NotificationCounterCircle>
                    </div>
                ) : null}
            </div>
        </button>
    );
};

export default TriggerButton;
