// @flow strict
type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

export const breakpoints: Array<[BreakpointType, number]> = [
    ['xs', 320],
    ['sm', 640],
    ['md', 1024],
    ['lg', 1366],
    ['xl', 1920],
];
