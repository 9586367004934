// @flow strict
import React, { type Node } from 'react';

import { Media, MediaContextProvider } from '@/core/media';
import Grid from '@/features/Grid';

import styles from './HeaderLayout.scss';

type Props = {|
    leftSlot?: Node,
    centerSlot?: Node,
    rightSlot?: Node,
    searchInputSlot?: Node,
|};

const HeaderLayout = ({ leftSlot, centerSlot, rightSlot, searchInputSlot }: Props): Node => {
    return (
        <MediaContextProvider>
            <Media greaterThanOrEqual="lg">
                <Grid containerClassName={styles.container}>
                    <Grid.Item xl={3} lg={3}>
                        {leftSlot}
                    </Grid.Item>
                    {!searchInputSlot ? (
                        <>
                            <Grid.Item xl={8} offsetXl={1} lg={8} offsetLg={1}>
                                {centerSlot}
                            </Grid.Item>
                            <Grid.Item xl={3} offsetXl={1} lg={3} offsetLg={1}>
                                <div className={styles.rightSlotContainer}>{rightSlot}</div>
                            </Grid.Item>
                        </>
                    ) : (
                        <Grid.Item xl={12} offsetXl={1} lg={12} offsetLg={1}>
                            <div className={styles.rightSlotContainer}>{searchInputSlot}</div>
                        </Grid.Item>
                    )}
                </Grid>
            </Media>
            <Media lessThan="lg">
                <Grid containerClassName={styles.container}>
                    <Grid.Item>
                        <div className={styles.mobileContainer}>
                            {!searchInputSlot ? (
                                <>
                                    <div className={styles.innerMobileContainer}>
                                        <div className={styles.mobileLeftSlotContainer}>{leftSlot}</div>
                                        <Media at="md">{centerSlot}</Media>
                                    </div>
                                    <div className={styles.rightSlotContainer}>{rightSlot}</div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.mobileLeftSlotContainer}>{leftSlot}</div>
                                    <div className={styles.rightSlotContainer}>{searchInputSlot}</div>
                                </>
                            )}
                        </div>
                    </Grid.Item>
                </Grid>
            </Media>
        </MediaContextProvider>
    );
};

export default HeaderLayout;
