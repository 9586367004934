// @flow strict

import { useContext } from 'react';

import { type CurrentUserType, type UserSettingsType } from '@/model/User';

import { CurrentUserContext } from './CurrentUserContext';

export const useCurrentUser = (): ?CurrentUserType => {
    const { currentUser } = useContext(CurrentUserContext);

    return currentUser;
};

/**
 * Проверка авторизации пользователя
 * false - пользователь не авторизован
 * true  - пользователь авторизован
 * null  - мы еще не знаем, ждем загрузки данных с сервера
 */
export const useIsLogin = (): null | boolean => {
    const { isLoaded, isLogin } = useContext(CurrentUserContext);
    if (!isLoaded) {
        return null;
    }
    return isLogin;
};

export const useCurrentUserSettings = (): UserSettingsType => {
    const { settings } = useContext(CurrentUserContext);

    return settings;
};

export const FEATURE = Object.freeze({
    beta: 'beta',
    feedbackForm: 'feedbackForm',
    articleIntroText: 'articleIntroText',
    darkTheme: 'darkTheme',
    articleLinkWidget: 'articleLinkWidget',
    relatedArticlesByKeywords: 'relatedArticlesByKeywords',
    articleAutoSave: 'articleAutoSave',
    articleCheckModified: 'articleCheckModified',
    promoHeaderLink: 'promoHeaderLink',
});

export type FeatureType = $Values<typeof FEATURE>;

export type FeaturesType = {
    [FeatureType]: boolean | string,
};

export const useFeatures = (): FeaturesType => {
    const settings = useCurrentUserSettings();

    /**
     * Сервер всегда возвращает нам строку, так как значение фичи может быть
     * - Строка: theme: 'dark'
     * - boolean: beta: 'true'
     * для удобства boolean, мы обрабатываем
     */
    const normalizeFeatureValue = (value: string): string | boolean => {
        if (value === 'true') {
            return true;
        }
        if (value === 'false') {
            return false;
        }
        return value;
    };

    const features: FeaturesType = settings.features.reduce((acc, feature) => {
        return {
            ...acc,
            [feature.name]: normalizeFeatureValue(feature.value),
        };
    }, {});

    return features;
};
