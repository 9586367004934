// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@xyz-school/xyz-react-frontend';

import Text from '@/typography/Text';

import styles from './SidebarMenuFooter.scss';

const SidebarMenuFooter = (): Node => {
    const { t } = useTranslation('navTitles');

    return (
        <div className={styles.container}>
            <Text size="label">{t('COPYRIGHT')}</Text>
            <Container marginTop={1}>
                <Text size="label">{t('DEVELOPED_AT')}</Text>
            </Container>
        </div>
    );
};

export default SidebarMenuFooter;
