import { useLocation } from 'react-router-dom';

type DataItemsType = {
    [key: string]: any;
};

/**
 * Builds url with parsed and encoded query params.
 *
 * @param url Base url
 * @param data Query params ([key: value])
 * @param removeEmpty Whether to remove empty values or not
 * @returns Parsed url with query params (if exits)
 *
 * @todo Заменить на готовую либу, например https://github.com/ljharb/qs
 */
export const buildUrl = (url: string, data?: DataItemsType, removeEmpty: boolean = true): string => {
    if (!url) {
        throw new TypeError('Param `url` is required');
    }

    if (!data || Object.keys(data).length === 0) {
        return url;
    }

    // remove empty params
    const params = data;
    if (removeEmpty) {
        Object.keys(params).forEach((i) => !params[i] && delete params[i]);
    }

    // parse query params
    const queryString = Object.keys(params)
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join('&');

    return queryString ? `${url}?${queryString}` : url;
};

/**
 * Gets value of param from url by name.
 */
export const getUrlParamValue = (name: string): string | null => {
    // FIXME
    // eslint-disable-next-line
    return new URLSearchParams(useLocation().search).get(name);
};
