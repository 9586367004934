// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import { Avatar, Text, Container, Link, CounterIcon } from '@xyz-school/xyz-react-frontend';

import { type SimpleUserType } from '@/model/User';
import imageKit from '@/core/imageKit';

import FollowAction from '../../../../journal/components/Common/FollowAction';
import { routes } from '../../../../journal/constants/urls';
import useUpdateSubscribers from '../../../../journal/hooks/useUpdateSubscribers';

import styles from './AuthorPreview.scss';

type Props = {|
    author: SimpleUserType,
    showFollowAction?: boolean,
    className?: string,
    isDataLoading?: boolean,
|};

const AuthorPreview = ({ author, showFollowAction = false, isDataLoading = false, className }: Props): Node => {
    const { subscribersCount } = useUpdateSubscribers(author.subscribersCount);
    return (
        <Container className={classnames(styles.wrap, className)}>
            <Link to={routes.PROFILE(author.id)}>
                <Container marginRight={2} marginRightXs={1}>
                    <Avatar
                        size="standard"
                        sizeXs="small"
                        alt={author.username}
                        src={imageKit(author.avatar, imageKit.SIZE.avatar_xs)}
                        userName={author.username}
                    />
                </Container>
            </Link>
            <div className={styles.info}>
                <Text className={styles.name} tag="div">
                    <Link className={styles.textWrapper} to={routes.PROFILE(author.id)}>
                        <span>{author.username}</span>
                    </Link>
                </Text>

                {author.information && (
                    <Text className={styles.information} size="small" color="label">
                        {author.information}
                    </Text>
                )}
                <div className={styles.countersWrapper}>
                    {!isDataLoading && (
                        <Container marginRight={2}>
                            <CounterIcon icon="like" count={author.likesCount || 0} />
                        </Container>
                    )}
                    {!isDataLoading && <CounterIcon icon="followers" count={subscribersCount} />}
                </div>
                {showFollowAction && <FollowAction user={author} />}
            </div>
        </Container>
    );
};

export default AuthorPreview;
