// @flow strict
import { useCallback, useEffect, useState } from 'react';
import useAxios from '@/core/useAxios';
import { usePulling } from '@xyz-school/xyz-react-frontend';
import { api } from '../../../journal/constants/api';

export type CountType = number;

const time = 60000;

type ReturnType = {
    unreadCount: CountType,
    setUnreadCount: (CountType) => void,
};

const usePullingNotification = (): ReturnType => {
    const [{ data }, fetch] = useAxios(api.NOTIFICATIONS_PULL, { useCache: false });
    const [unreadCount, setUnreadCount] = useState<CountType>(0);
    const count = data && typeof data.unreadCount === 'number' ? data.unreadCount : 0;

    useEffect(() => {
        setUnreadCount(count);
    }, [count]);

    const getData = useCallback(
        () =>
            fetch().catch(() => {
                // FIXME add error handler
            }),
        [fetch],
    );

    usePulling(time, getData);

    return {
        unreadCount,
        setUnreadCount,
    };
};
export default usePullingNotification;
