// @flow strict
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalizedHistory, useQueryUrl } from '@xyz-school/xyz-react-frontend';
import { SORT_TYPE, type SortTypeType, normalizeSort } from '@/core/sort';
import { buildUrl } from '../../../journal/utils/url';

export type FiltersType = {|
    topic: ?string,
    sort: SortTypeType,
    offset: number,
|};

type SetFilterType = ($Shape<FiltersType>) => void;

type GetUrlType = ($Shape<FiltersType>) => string;

type ReturnType = [FiltersType, SetFilterType, GetUrlType];

type GetUrlUtilType = (FiltersType) => GetUrlType;

export const defaultSort = SORT_TYPE.new;

export const getUrlUtil: GetUrlUtilType = (currentFiler) => (newFilter) => {
    const topic = typeof newFilter.topic !== 'undefined' ? newFilter.topic : currentFiler.topic;
    const sort = typeof newFilter.sort !== 'undefined' ? newFilter.sort : currentFiler.sort;
    const normalizedSort = normalizeSort(sort, defaultSort);
    const offset = newFilter.offset || '';

    return buildUrl(topic === '' ? '/' : `/topic/${topic}`, {
        sort: normalizedSort === defaultSort ? '' : normalizedSort,
        offset,
    });
};

const useFilter = (): ReturnType => {
    const history = useLocalizedHistory();
    const { topicSlug } = useParams();
    const { params } = useQueryUrl();

    const filter: FiltersType = useMemo(
        () => ({
            topic: topicSlug || '',
            sort: normalizeSort(params.sort, defaultSort),
            offset: Number(params.offset) || 0,
        }),
        [topicSlug, params.sort, params.offset],
    );

    const getUrl = useCallback(getUrlUtil(filter), [filter]);

    const setFilter = useCallback(
        (newFilters) => {
            history.push(getUrl(newFilters));
        },
        [history, getUrl],
    );

    return [filter, setFilter, getUrl];
};

export default useFilter;
