import React from 'react';
import { useTranslation } from 'react-i18next';

import {
    Container,
    Heading,
    Link,
    // @ts-ignore
    Modal,
    Separator,
    Text,
    useLanguage,
    Icon,
    Media,
} from '@xyz-school/xyz-react-frontend';

import AuthModalSocialButtons from '@/features/AuthModal/AuthModalSocialButtons';

import { routes } from '../../../journal/constants/urls';

import styles from './AuthModalForm.scss';

type Props = {
    connections: Array<{
        provider: string;
    }>;
    onConnectionClick: (provider: string) => void;
    onClose: () => void;
};

const AuthModalForm = ({ connections, onConnectionClick, onClose }: Props) => {
    const { currentLang } = useLanguage();
    const { t } = useTranslation(['auth']);

    return (
        <Modal
            onClose={onClose}
            isOpened
            showCloseButton
            header={
                <Media lessThan="lg">
                    <div className={styles.header_wrapper}>
                        <header className={styles.header}>
                            <div className={styles.header_cancel}>
                                <button type="button" onClick={onClose} className={styles.header_button}>
                                    <Icon name="close" color="text" />
                                </button>
                            </div>
                        </header>
                    </div>
                    <Separator marginBottom={0} marginTop={0} />
                </Media>
            }
        >
            <Container
                paddingRight={6}
                paddingRightXs={1.5}
                paddingLeft={6}
                paddingLeftXs={1.5}
                paddingTop={5}
                paddingTopXs={4}
                paddingBottom={5}
                paddingBottomXs={4}
                className={styles.container}
            >
                <Heading level={1}>{t('TITLE')}</Heading>
                <Container marginTop={4} marginBottom={3}>
                    <AuthModalSocialButtons connections={connections} onClick={onConnectionClick} />
                </Container>
                <Separator marginBottom={2} />
                <Link to={`${window.location.origin}${routes.LOGIN({ lang: currentLang })}`}>
                    <Text size="small" color="accent">
                        {t('LOGIN_OR_REGISTER_BY_EMAIL')}
                    </Text>
                </Link>
            </Container>
        </Modal>
    );
};

export default AuthModalForm;
