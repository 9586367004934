// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import styles from './GridContainer.scss';

type Props = {|
    children: Node,
    className?: string,
|};

const GridContainer = ({ children, className }: Props): Node => {
    return <div className={classnames(styles.container, className)}>{children}</div>;
};

export default GridContainer;
