// @flow strict
import React, { type Node } from 'react';
import classnames from 'classnames';

import { textConvertToMultiLine } from '@xyz-school/xyz-react-frontend/lib/utils/text';

import styles from './Heading.scss';

export type HeadingColorType =
    | 'inherit'
    | 'text'
    | 'text-inverted'
    | 'label'
    | 'input'
    | 'accent'
    | 'hover'
    | 'success'
    | 'warning'
    | 'fail'
    | 'accent-lite'
    | 'success-lite'
    | 'warning-lite'
    | 'fail-lite';

export type HeadingSizeType = 2 | 3;

export type Props = {|
    level: HeadingSizeType,
    children: Node,
    color?: HeadingColorType,
    inline?: boolean,
    wrap?: boolean,
    multiLine?: boolean,
    className?: string,
|};

const Heading = ({
    level,
    children,
    color = 'text',
    inline = false,
    wrap = false,
    multiLine = false,
    className,
}: Props): Node => {
    const Tag = `h${level}`;

    return (
        <Tag
            className={classnames([styles[`heading--h${level}`]], className, {
                [styles[`heading-color--${String(color)}`]]: color !== 'text',
                [styles['heading--inline']]: inline,
                [styles['heading--wrap']]: wrap,
            })}
        >
            {multiLine ? textConvertToMultiLine(children) : children}
        </Tag>
    );
};

export default Heading;
