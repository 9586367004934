// @flow strict
import React, { type Node } from 'react';
import { useTranslation } from 'react-i18next';

import { Container, GridContainer, Separator, ModalHeader, Modal } from '@xyz-school/xyz-react-frontend';

import TriggerButton from '../TriggerButton';

type Props = {|
    children: Node,
    count: number,
    isOpen: boolean,
    closeNotifications: () => void,
    openNotifications: () => Promise<void>,
|};

const MobileView = ({ children, count, isOpen, closeNotifications, openNotifications }: Props): Node => {
    const { t } = useTranslation(['header']);

    return (
        <>
            <TriggerButton onClick={openNotifications} count={count} isOpened={isOpen} />
            <Modal
                isOpened={isOpen}
                onClose={closeNotifications}
                header={
                    <>
                        <GridContainer>
                            <ModalHeader onCancel={closeNotifications} title={t('NOTIFICATION_TITLE')} />
                        </GridContainer>
                        <Separator marginTop={0} marginBottom={0} />
                    </>
                }
            >
                <GridContainer>
                    <Container paddingTopXs={2} paddingBottomXs={2}>
                        {children}
                    </Container>
                </GridContainer>
            </Modal>
        </>
    );
};

export default MobileView;
