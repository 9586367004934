// @flow strict
import React, { type Node, useCallback, useState } from 'react';

import { useLocalizedHistory, useQueryParam } from '@xyz-school/xyz-react-frontend';

import NotificationsWrapper from '@/features/PageLayout/HeaderWrapper/NotificationsWrapper';
import HeaderTopicsMenu from '@/features/Header/HeaderTopicsMenu';
import { useCurrentUser } from '@/model/User';
import HeaderUserMenu from './HeaderUserMenu';
import { routes } from '../../journal/constants/urls';
import HeaderControls from './HeaderControls';
import HeaderSearch from './HeaderSearch';

import HeaderLayout from './HeaderLayout';
import HeaderLogo from './HeaderLogo';
import HeaderNavigation from './HeaderNavigation';

const Header = (): Node => {
    const [searchQuery] = useQueryParam('q');
    const hasSearchQuery = !!searchQuery && searchQuery.length > 0;

    const history = useLocalizedHistory();

    const currentUser = useCurrentUser();

    const [isSearchMode, setSearchMode] = useState(hasSearchQuery);
    const [isProfileMenuOpened, setIsProfileMenuOpened] = useState(false);
    const [isTopicsMenuOpened, setIsTopicsMenuOpened] = useState(false);

    const handleSearchModeChange = useCallback((val: boolean) => setSearchMode(val), []);

    const handleCloseSearch = useCallback(() => {
        handleSearchModeChange(false);
    }, []);

    const onSubmitSearch = useCallback(
        (query) => {
            if (query.length > 0) {
                history.push({ pathname: routes.SEARCH, search: `?q=${query}` });
            }
        },
        [history, routes.SEARCH],
    );

    const handleSearchClick = () => {
        handleSearchModeChange(true);
    };

    return (
        <HeaderLayout
            leftSlot={<HeaderLogo />}
            centerSlot={<HeaderNavigation />}
            rightSlot={
                <HeaderControls
                    onSearchClick={handleSearchClick}
                    notifications={<NotificationsWrapper />}
                    userMenu={
                        <HeaderUserMenu
                            user={currentUser}
                            isMenuOpened={isProfileMenuOpened}
                            setIsMenuOpened={setIsProfileMenuOpened}
                        />
                    }
                    topicsMenu={
                        <HeaderTopicsMenu
                            isTopicsMenuOpened={isTopicsMenuOpened}
                            setIsTopicsMenuOpened={setIsTopicsMenuOpened}
                        />
                    }
                />
            }
            searchInputSlot={
                isSearchMode && (
                    <HeaderSearch isOpened={isSearchMode} onSubmit={onSubmitSearch} onClose={handleCloseSearch} />
                )
            }
        />
    );
};

export default Header;
