export const api = {
    COURSE_ADS: (slug: string) => `/api/course_ads/${slug}/`,
    ARTICLES: '/api/articles/',
    ARTICLE_LIKE: (articleId: number) => `/api/articles/${articleId}/like/`,
    BLOGS: '/api/articles/?topic=user-blogs',
    SEARCH: '/api/articles/',
    SUBSCTIPTION: '/api/email_subscription/',
    USERS: '/api/users/',
    USER: (userId: string) => `/api/users/${userId}`,
    CHANGE_PASSWORD: '/api/users/change_password/',
    COMMENTS: '/api/comments/',
    USER_SUBS: '/api/user_subscriptions/',
    NOTIFICATIONS: '/api/notifications/',
    NOTIFICATIONS_PULL: '/api/notifications/count/',
    INFO: (slug: string) => `/api/info/${slug}`,
    FEED_ADS: '/api/feed_ads/',
};
