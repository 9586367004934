// @flow strict
import { createContext } from 'react';

import { type SortTypeType } from '@/core/sort';

import { type CommentType, type CommentsType } from '../commentType';
import { type ActiveCommentType } from './activeCommentType';

type CommentsContextType = {
    sort: SortTypeType,
    comments: CommentsType,
    loading: boolean,
    fetchComments: () => void,
    fetchTread: (thredId: number) => void,
    remove: (id: number) => void,
    replace: (data: CommentType) => void,
    activeComment?: ?ActiveCommentType,
};

const CommentsContext: React$Context<CommentsContextType> = createContext<CommentsContextType>({});

export default CommentsContext;
